import { Component, ElementRef, EventEmitter, Input, OnDestroy, OnInit, Output, ViewChild } from '@angular/core';
import { FormArray, FormBuilder, FormControl, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { ModalConfirmComponentAction } from 'src/app/shared/components/modal-confirm/modal-confirm.component';
import { requiredWithout } from 'src/app/shared/directives/required-without.directive';
import { Group } from 'src/app/shared/models/group.model';
import { TestQuestionAnswer } from 'src/app/shared/models/test-question-answer.model';
import { TestQuestion } from 'src/app/shared/models/test-question.model';
import { TestType } from 'src/app/shared/models/test-type.model';
import { Test } from 'src/app/shared/models/test.model';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { GroupService } from 'src/app/shared/services/group.service';
import { TestService, TestServiceIndex } from 'src/app/shared/services/test.service';
import { environment } from 'src/environments/environment';
import { UploadcomponentActionName, UploadComponentQueue } from 'src/app/shared/components/upload/upload.component';
import { translate } from '@ngneat/transloco';
import { User } from 'src/app/shared/models/user/user.model';
import { AuthService } from 'src/app/shared/services/auth.service';
import { CourseService } from '../../services/course.service';
import { Course } from '../../models/course.model';
import { Location } from '@angular/common';
import { Paginate } from '../../models/paginate.model';
import { ModalItemsChooseComponentItem } from '../modal-items-chooser/modal-items-chooser.component';

@Component({
    selector: 'page-exam-form',
    templateUrl: './page-exam-form.component.html',
    styleUrls: ['./page-exam-form.component.scss'],
})
export class PageExamFormComponent implements OnInit, OnDestroy {
    protected readonly FINAL_TEST_MAX_POINTS: number = 100;
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;
    protected readonly CACHING_MAX_TIME: number = environment.caching.default;

    protected innerLoadingQueue: number = 0;
    protected subscriptions: Subscription[] = [];
    protected innerGroupId: number|null = null;
    protected innerCourseId: number|null = null;
    protected innerId: number|null = null;
    protected innerSubmit: boolean = false;

    @ViewChild('questionInput') questionInput?: ElementRef;
    @ViewChild('questionsAccordion') questionsAccordion?: ElementRef;

    user: User = new User;
    question: string = '';
    typesList: TestType[] = [];
    modulesList: Array<{value?: number, label?: string, disabled?: boolean}> = [];
    group: Group|null = null;
    course: Course|null = null;
    exam: Test|null = null;

    actionPanelOverlapping: boolean = false;
    countQuestions: number = 0;
    countQuestionsActive: number = 0;

    form?: FormGroup;
    showExport: boolean = false;
    showDelete: boolean = false;
    showWarning: boolean = false;
    showCopyFromDialog: boolean = false;
    warning: string|null = null;
    warningActions: ModalConfirmComponentAction[] = [];

    exams?: Paginate<Test>;
    examsList: ModalItemsChooseComponentItem[] = [];
    examsFilter: TestServiceIndex = {
        page: 1,
        sort: [{direction: 'asc', field: 'name'}],
        limit: this.PAGINATION_LIMIT,
        q: '',
        include: ['type'],
    };

    @Input() mode: ('add'|'edit') = 'add';
    @Input() isGlobal: boolean = false;

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    set submit(value: boolean) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = false;
            value && this.onSubmit();

            setTimeout(() => {
                this.submitChange.emit(this.innerSubmit);
            });
        }
    }
    get submit(): boolean {
        return this.innerSubmit;
    }

    @Input() moduleId: number|null = null;

    @Input()
    set groupId(value: number|null) {
        if (this.innerGroupId !== value) {
            this.innerGroupId = value;
            this.innerGroupId && this.fetchGroupDetails(this.innerGroupId);
        }
    }
    get groupId(): number|null {
        return this.innerGroupId;
    }

    @Input()
    set courseId(value: number|null) {
        if (this.innerCourseId !== value) {
            this.innerCourseId = value;
            this.innerCourseId && this.fetchGroupDetails(this.innerCourseId, true);
        }
    }
    get courseId(): number|null {
        return this.innerCourseId;
    }

    @Input()
    set id(value: number|null) {
        if (this.innerId !== value) {
            this.innerId = parseInt('' + value, 10);
            this.innerId && this.fetchExamDetails(this.innerId);
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            if (this.form) {
                if (this.loading) {
                    this.form?.disable();
                } else {
                    this.form?.enable();
                }
            }

            this.loadingChange.emit(this.loading);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    get questionControls(): FormArray<FormGroup>|null {
        return this.form?.get('questions') as FormArray;
    }

    constructor(
        private router: Router,
        private messageService: MessageService,
        private testService: TestService,
        private groupService: GroupService,
        private formBuilder: FormBuilder,
        private apiValidation: ApiValidation,
        private authService: AuthService,
        private courseService: CourseService,
        private locationService: Location,
    ) {
        this.user = this.authService.getUser() ?? this.user;
    }

    ngOnInit(): void {
        this.fetchTestTypes();
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    onButtonClicked(buttonId: string): void {
        this.showDelete = (buttonId === 'remove');

        if (buttonId === 'export') {
            this.exportAsJson();
        }

        if (buttonId === 'copy_from_exam') {
            this.examsFilter.page = 1;
            this.examsFilter.q = '';

            this.getExamsList();
            this.showCopyFromDialog = true;
        }
    }

    protected exportAsJson(): void {
        const data = this.form?.getRawValue();
        const jsonStr = JSON.stringify(data);
        const blob = new Blob([jsonStr], { type: 'application/json' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.href = url;
        link.download = ('' + (this.form?.get('name')?.value || 'file'))?.trim().replace(/\s+/g, '_') || 'file';
        document.body.appendChild(link);
        link.click();

        document.body.removeChild(link);
        URL.revokeObjectURL(url);
    }

    protected importFromJson(event?: {id?: string, action: UploadcomponentActionName, response: any}) {
        if (event?.action !== 'onFilesFinished' || event?.id !== 'import') {
            return;
        }

        try {
            const data = JSON.parse(event?.response?.files[0]?.response);
            if (typeof data !== 'object') {
                throw new Error('Invalid parsed data.');
            }

            const module = this.form?.get('module')?.value;
            this.form?.reset();
            data.type = TestType.fromJson({title: data.type});
            data.module = module;
            data.status = data.status ? 'active' : 'inactive'
            data.questions = data?.questions?.map((question: TestQuestion) => {
                question.status = question?.status ? 'active' : 'inactive';
                question.answers = question?.answers?.map(answer => {
                    answer.status = answer?.status ? 'active' : 'inactive';
                    return answer;
                });
                return question;
            });
            this.exam = data;
            this.updateForm();
        } catch (e) {
            this.messageService.add({
                severity: 'error',
                detail: translate("Невалиден формат, проблем с качването или файлът е прекалено голям: ")
                    + event?.response?.files[0]?.file?.name,
            });
        }
    }

    onSubmit(event?: any): void {
        if (this.loading) {
            return;
        }

        if (!this.form?.valid) {
            this.form?.markAllAsTouched();
            return;
        }

        const formData = this.form?.getRawValue();
        formData.status = formData.status === true ? 'active' : 'inactive';
        formData.max_points = this.form?.get('max_points')?.value ?? this.FINAL_TEST_MAX_POINTS;
        formData.questions = formData?.questions?.map((question: any) => {
            question.image = question?.path ?? question?.image ?? null;
            question.status = question?.status === true ? 'active' : 'inactive';
            question.answers = question?.answers?.map((answer: any) => {
                answer.image = answer?.path ?? answer?.image ?? null;
                answer.status = answer?.status === true ? 'active' : 'inactive';
                return answer;
            });

            return question;
        });

        const hasOpenQuestions = !!formData
            .questions?.find((question: any) => question.answers?.filter((item: TestQuestionAnswer) => item.status === 'active')?.length < 2);

        const hasQuestions = formData.questions?.filter((item: TestQuestion) => item.status === 'active')?.length > 0;
        const HasQuestionsWithSingleAnswer = formData.questions?.filter(function(item: TestQuestion) {
                    return item.status === 'active' && (item?.answers?.filter(a => a.status === 'active')?.length ?? 0) === 1
                })?.length

        const HasQuestionsWithoutAnswers = formData.questions?.filter(function(item: TestQuestion) {
                    return item.status === 'active' && (item?.answers?.filter(a => a.status === 'active')?.length ?? 0) <= 1
                })?.length
            && !formData.questions?.filter(function(item: TestQuestion) {
                    return item.status === 'active' && (item?.answers?.filter(a => a.status === 'active')?.length ?? 0) > 1
                })?.length;

        if (event === 'no' || event === 'close') {
            this.showWarning = false;
            return;
        } else if (formData.type === 'midterm' && hasOpenQuestions) {
            this.showWarning = true;
            this.warning = translate("При междинен тест не може да имате отворени въпроси. Моля, въведете поне два възможни отговора към всеки въпрос.");
            this.warningActions = [{
                name: translate('ОК'), action: 'no', btnClass: 'btn-secondary', tooltip: ''
            }];
            return;
        } else if (formData.type === 'modal' && hasOpenQuestions) {
            this.showWarning = true;
            this.warning = translate("При модулен тест не може да имате отворени въпроси. Моля, въведете поне два възможни отговора към всеки въпрос.");
            this.warningActions = [{
                name: translate('ОК'), action: 'no', btnClass: 'btn-secondary', tooltip: ''
            }];
            return;
        } else if (hasOpenQuestions && hasQuestions && (HasQuestionsWithoutAnswers || HasQuestionsWithSingleAnswer)) {
            this.showWarning = true;
            this.warning = translate("Моля, въведете поне един активен въпрос с два или повече активни възможни отговора. Не е възможно създаването на тест само с отворени въпроси.");
            this.warningActions = [{
                name: translate('ОК'), action: 'no', btnClass: 'btn-secondary', tooltip: ''
            }];
            return;
        } else if (event !== 'yes' && hasOpenQuestions && hasQuestions) {
            this.showWarning = true;
            this.warning = translate("Тестът съдържа въпроси без възможни отговори. Това ще създаде автоматично втора част на теста – „практически тест“, където курсистите ще въвеждат отговори на въпросите като свободен текст. Желаете ли да продължите?"),

            this.warningActions = [{
                name: translate('Продължение'), action: 'yes', btnClass: 'btn-primary', tooltip: translate('Потвърждение'), icon: 'fa-solid fa-check fa-fw'
            }, {
                name: translate('Отказ'), action: 'no', btnClass: 'btn-secondary', tooltip: translate('Отказване'), icon: 'fa-solid fa-xmark fa-fw'
            }];

            return;
        } else if (!hasQuestions) {
            this.showWarning = true;
            this.warning = translate("Трябва да добавите поне един въпрос, който не е със статус „Скрит“, за да продължите.");
            this.warningActions = [{
                name: translate('ОК'), action: 'no', btnClass: 'btn-secondary', tooltip: ''
            }];
            return;
        }

        this.showWarning = false;

        const action = this.id && this.mode === 'edit'
            ? this.testService.editTest(this.id, formData)
            : this.testService.addTest(formData);

        this.loadingQueue++;
        const subscription = action.subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                if (this.id && this.mode === 'edit') {
                    this.messageService.add({
                        severity: 'success',
                        detail: translate('Успешно редактиран тест!'),
                    });
                } else {
                    this.messageService.add({
                        severity: 'success',
                        detail: translate('Успешно добавен тест!'),
                    });
                }

                this.id = data.data.id;
                this.mode = 'edit';
                this.exam = data.data;
                this.updateForm();

                const navigate = this.groupId
                    ? ('/groups/' + this.groupId + '/exams/edit/' + this.id)
                    : ('/courses/' + this.courseId + '/exams/edit/' + this.id);

                this.router.navigateByUrl(navigate, {skipLocationChange: true});
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                });
            }
        });

        this.subscriptions.push(subscription);
    }

    protected initForm(): void {
        this.form = this.formBuilder.group({
            type: [this.exam?.type?.title ?? this.typesList[0]?.title ?? 'final', [Validators.required]],
            module: [this.moduleId ?? this.exam?.module ?? this.modulesList.find(item => !item.disabled)?.value ?? 0, [Validators.required, Validators.min(1)]],
            group: [this.group?.id ?? 0, []],

            name: [this.exam?.name ?? '', [Validators.required, Validators.maxLength(100)]],
            course: [this.exam?.course ?? this.group?.course?.title ?? '', [Validators.required, Validators.maxLength(150)]],
            profession: [this.exam?.profession ?? '', [Validators.required, Validators.maxLength(150)]],
            specialty: [this.exam?.specialty ?? '', [Validators.required, Validators.maxLength(150)]],

            pass_percentage: [this.exam?.pass_percentage ?? 100, [Validators.required, Validators.min(0), Validators.max(100)]],
            max_points: [this.exam?.max_points ?? this.FINAL_TEST_MAX_POINTS, [Validators.required, Validators.min(1), Validators.max(1000)]],
            duration: [this.exam?.duration ?? 30, [Validators.required, Validators.min(1), Validators.max(1440)]],

            status: [(this.exam?.status ?? 'active') === 'active' ? true : false, []],

            questions: this.formBuilder.array([]),
        });

        this.questionControls?.clear();
        this.exam?.questions?.map(item => {
            this.questionItemAdd(item);
        });

        const finalTestSwitch: (isFinal: boolean) => void = (isFinal: boolean) => {
            const maxPoints = this.form?.get('max_points');
            [
                'course',
                'profession',
                'specialty',
            ].map(item => this.form?.get(item))
            ?.map(item => {
                item?.clearValidators();
                item?.disable();

                if (isFinal) {
                    item?.addValidators([
                        Validators.required,
                        Validators.maxLength(150)
                    ]);
                    item?.enable();
                } else {
                    item?.setValue('');
                }
                item?.updateValueAndValidity()
            });

            if (isFinal) {
                maxPoints?.disable();
                maxPoints?.setValue(this.FINAL_TEST_MAX_POINTS);
            } else {
                maxPoints?.enable();
            }
        }

        this.form?.get('type')?.valueChanges?.subscribe(value => {
            finalTestSwitch(this.typesList?.find(item => item?.title === value)?.is_final ?? false)
        });
        this.form?.get('type')?.updateValueAndValidity();
    }

    protected updateForm(): void {
        if (!this.form) {
            return;
        }

        this.form?.get('type')?.setValue(this.exam?.type?.title ?? this.typesList[0]?.title ?? 'final');

        this.form?.get('module')?.setValue(this.moduleId ?? this.exam?.module ?? this.modulesList.find(item => !item.disabled)?.value ?? 0);
        this.form?.get('group')?.setValue(this.group?.id ?? 0, []),

        this.form?.get('name')?.setValue((this.exam?.name ?? '').trim());
        this.form?.get('course')?.setValue((this.exam?.course ?? this.group?.course?.title ?? '').trim());
        this.form?.get('profession')?.setValue((this.exam?.profession ?? '').trim());
        this.form?.get('specialty')?.setValue((this.exam?.specialty ?? '').trim());

        this.form?.get('pass_percentage')?.setValue(this.exam?.pass_percentage ?? 100);
        this.form?.get('max_points')?.setValue(this.exam?.max_points ?? this.FINAL_TEST_MAX_POINTS);
        this.form?.get('duration')?.setValue(this.exam?.duration ?? 30);

        this.form?.get('status')?.setValue((this.exam?.status ?? 'active') === 'active' ? true : false);

        this.questionControls?.clear();
        this.exam?.questions?.map(item => {
            this.questionItemAdd(item);
        });

        this.form?.get('type')?.updateValueAndValidity();
    }

    protected fetchTestTypes(): void {
        this.loadingQueue++;

        const subscription = this.testService.getTestTypes(this.CACHING_MAX_TIME).subscribe({
            next: data => {
                this.loadingQueue--;

                this.typesList = data?.data;

                this.initForm();
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }

    protected fetchExamDetails(id: number, keepModule: boolean = false): void {
        this.loadingQueue++;

        const subscription = this.testService.getItem(id).subscribe({
            next: data => {
                this.loadingQueue--;
                this.exam = data.data;

                const module = this.form?.get('module')?.value ?? null;

                this.updateForm();

                if (keepModule) {
                    this.form?.get('module')?.setValue(module);
                }
            },
            error: error => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'error',
                    detail: translate('Тестът не е намерен!'),
                });

                this.locationService.back();
            }
        });
        this.subscriptions.push(subscription);
    }

    protected fetchGroupDetails(id: number, isCourse: boolean = false):void {
        this.loadingQueue++;

        if (isCourse) {
            const subscription = this.courseService.getItem(id, {include: ['modules']}).subscribe({
                next: (data: any) => {
                    this.loadingQueue--;
                    this.course = data.data;
                    this.modulesList = this.course?.modules?.map(item => {
                        return {
                            value: item?.id ?? 0,
                            label: item?.number + '. ' + (item?.title ?? ''),
                            disabled: !!(item?.test?.id && item?.test?.id !== this.id),
                        };
                    }) ?? [];

                    this.updateForm();
                }, error: error => {
                    this.loadingQueue--;

                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: translate('Групата не е намерена'),
                    });

                    this.locationService.back();
                }
            });

            this.subscriptions.push(subscription);
        } else {
            const subscription = this.groupService.getGroup(id).subscribe({
                next: (data: any) => {
                    this.loadingQueue--;
                    this.group = data.data;
                    this.modulesList = this.group?.modules?.map(item => {
                        return {
                            value: item?.id ?? 0,
                            label: item?.number + '. ' + (item?.title ?? ''),
                            disabled: item?.test?.id && item?.test?.id !== this.id && (item?.test?.group ?? 0) > 0,
                        } as {value?: number, label?: string, disabled?: boolean};
                    }) ?? [];

                    this.updateForm();
                },
                error: error => {
                    this.loadingQueue--;

                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: translate('Групата не е намерена'),
                    });

                    this.locationService.back();
                }
            });

            this.subscriptions.push(subscription);
        }
    }

    protected questionItemAdd(question?: TestQuestion, imagePath: string|null = null, isNewQuestion: boolean = false): FormGroup {
        const imageControl = new FormControl(question?.image ?? '', [Validators.maxLength(255)]);
        const pathControl = new FormControl(imagePath ?? question?.image ?? '', [Validators.maxLength(255)]);
        const questionGroup = this.formBuilder.group({
            id: [question?.id ?? '', []],
            question: [question?.question ?? '', [Validators.maxLength(255), Validators.required]],
            image: imageControl,
            path: pathControl,
            select: [false, []],
            status: [(question?.status ?? 'active') === 'active', []],

            is_new: [isNewQuestion ?? false, []],

            answer: ['', [Validators.maxLength(255)]],
            answers: this.formBuilder.array([]),
        });

        this.questionControls?.push(questionGroup);

        this.countQuestions = this.questionControls?.controls?.length ?? 0;
        this.countQuestionsActive = this.questionControls?.controls?.filter(item => item?.get('status')?.value)?.length ?? 0;

        question?.answers?.map(answer => {
            this.answerItemAdd(questionGroup, answer);
        });

        return questionGroup;
    }

    protected questionItemRemove(event?: Event, index: number = 0): void {
        event?.preventDefault();

        this.questionControls?.removeAt(index);

        this.countQuestions = this.questionControls?.controls?.length ?? 0;
        this.countQuestionsActive = this.questionControls?.controls?.filter(item => item?.get('status')?.value)?.length ?? 0;
    }

    onQuestionsToggle(event: Event, expand: boolean = false) {
        const selector = '.collapse' + (expand ? ':not(.show)' : '.show');

        this.questionsAccordion?.nativeElement
            .querySelectorAll(selector)
            .forEach((element: HTMLElement) => {
                // @ts-ignore
                new bootstrap.Collapse(element);
            });
    }

    addQuestion(event?: Event): boolean {
        event?.preventDefault();

        const text = this.question?.trim();
        const question: TestQuestion = TestQuestion.fromJson({question: text?.length ? text : translate('Въпрос {{count}}', {count: this.countQuestionsActive + 1}) });

        this.questionItemAdd(question, undefined, true);
        this.afterAddQuestion();

        return false;
    }

    addQuestionOnFileUpload(event?: {files: UploadComponentQueue[]}, questionGroup?: FormGroup, questionElement?: HTMLElement): void {
        if (!event?.files || !event?.files.length || event?.files[0].error) {
            this.messageService.add({
                severity: 'error',
                detail: translate("Невалидно изображение, грешка при ъплоуд или прекалено голям файл."),
            });

            this.loadingQueue--;
            return;
        }

        const image = event?.files[0]?.response?.data;

        if (!questionGroup) {
            const question: TestQuestion = TestQuestion.fromJson({image: image?.url, question: translate('Въпрос {{count}}', {count: this.countQuestionsActive + 1}) });
            this.questionItemAdd(question, image?.path, true);
        } else {
            questionGroup.get('image')?.setValue(image?.url);
            questionGroup.get('path')?.setValue(image?.path);
        }

        this.loadingQueue--;

        this.afterAddQuestion(questionElement);
    }

    protected afterAddQuestion(wrapper?: HTMLElement): void {
        setTimeout(() => {
            this.question = '';

            const element: HTMLElement = wrapper ?? (this.questionsAccordion?.nativeElement?.lastElementChild as HTMLElement);
            element && !this.isElementInViewport(element) && window.scrollTo({
                top: (element?.offsetTop ?? 0) + 60,
                left: 0,
                behavior: 'smooth',
            });

            this.questionInput?.nativeElement?.focus();
        }, 100);
    }

    onImageRemove(event: Event, formGroup: FormGroup): boolean {
        event?.preventDefault();
        formGroup?.get('image')?.setValue('');
        formGroup?.get('path')?.setValue('');
        return false;
    }

    onActionQuestions(event: Event, action: ('inactive'|'active'|'delete'), index: number|null = null): boolean {
        event?.preventDefault();

        if (index !== null && index >= 0) {
            if (action === 'delete') {
                this.questionItemRemove(event, index);
            } else {
                this.questionControls?.at(index).get('status')?.setValue(action === 'active');
            }

            this.countQuestions = this.questionControls?.controls?.length ?? 0;
            this.countQuestionsActive = this.questionControls?.controls?.filter(item => item?.get('status')?.value)?.length ?? 0;

        } else {
            this.questionControls?.controls?.filter(item => item?.get('select')?.value)?.reverse().map(item => {
                if (action === 'active') {
                    item?.get('status')?.setValue(true);
                } else if (action === 'inactive') {
                    item?.get('status')?.setValue(false);
                } else if (action === 'delete') {
                    const index = this.questionControls?.controls.indexOf(item);

                    this.questionItemRemove(event, index);
                }
            });

            this.countQuestionsActive = this.questionControls?.controls?.filter(item => item?.get('status')?.value)?.length ?? 0;
        }

        return false;
    }

    protected answerItemAdd(questionGroup?: FormGroup, answer?: TestQuestionAnswer, imagePath?: string): FormGroup {
        const imageControl = new FormControl(answer?.image ?? '', [Validators.maxLength(255)]);
        const pathControl = new FormControl(imagePath ?? answer?.image ?? '', [Validators.maxLength(255)]);
        const answerGroup = this.formBuilder.group({
            id: [answer?.id ?? null, []],
            answer: [answer?.answer ?? '', [Validators.maxLength(255), requiredWithout([imageControl, pathControl])]],
            image: imageControl,
            path: pathControl,
            is_correct: [answer?.is_correct ?? false, []],
            status: [(answer?.status ?? 'active') === 'active', []],
        });

        this.countQuestions = this.questionControls?.controls?.length ?? 0;
        this.countQuestionsActive = this.questionControls?.controls?.filter(item => item?.get('status')?.value)?.length ?? 0;

        const answers = questionGroup?.get('answers') as FormArray<FormGroup>;
        answers.push(answerGroup);

        return answerGroup;
    }

    protected answerItemRemove(questionGroup?: FormGroup, index?: number) {
        const answers = questionGroup?.get('answers') as FormArray<FormGroup>;
        index != null && index >= 0 && answers.removeAt(index);

        this.checkAndUpdateCorrectAnswer(questionGroup);
    }

    protected checkAndUpdateCorrectAnswer(questionGroup?: FormGroup): void {
        const answers = (questionGroup?.get('answers') as FormArray)?.controls;
        if (answers && answers?.length && answers?.find(item => item.get('is_correct')?.value && item.get('status')?.value) === undefined) {
            answers.map(item => (item.get('is_correct')?.setValue(false), item));
            answers.find(item => item.get('status')?.value)?.get('is_correct')?.setValue(true);
        }
    }

    addAnswer(event?: Event, questionGroup?: FormGroup, answersWrapper?: HTMLElement, answerInput?: HTMLElement): boolean {
        event?.preventDefault();

        const val = questionGroup?.get('answer')?.value?.trim();
        const answer: TestQuestionAnswer = TestQuestionAnswer.fromJson({answer: val});
        this.answerItemAdd(questionGroup, answer);

        this.checkAndUpdateCorrectAnswer(questionGroup);

        this.afterAddAnswer(questionGroup, answersWrapper, answerInput);

        return false;
    }

    addAnswerOnFileUpload(event?: {files: UploadComponentQueue[]}, questionGroup?: FormGroup, answerGroup?: FormGroup, answerActionPanel?: HTMLElement, answerInput?: HTMLElement): void {
        if (!event?.files || !event?.files.length || event?.files[0].error) {
            this.messageService.add({
                severity: 'error',
                detail: translate("Невалидно изображение, грешка при ъплоуд или прекалено голям файл."),
            });

            this.loadingQueue--;
            return;
        }

        const image = event?.files[0]?.response?.data;

        if (!answerGroup) {
            const answer: TestQuestionAnswer = TestQuestionAnswer.fromJson({image: image?.url});
            this.answerItemAdd(questionGroup, answer, image?.path);
        } else {
            answerGroup.get('image')?.setValue(image?.url);
            answerGroup.get('path')?.setValue(image?.path);
        }

        this.checkAndUpdateCorrectAnswer(questionGroup);

        this.loadingQueue--;

        this.afterAddAnswer(questionGroup, answerActionPanel, answerInput);
    }

    protected afterAddAnswer(questionGroup?: FormGroup, answerActionPanel?: HTMLElement, answerInput?: HTMLElement): void {
        setTimeout(() => {
            questionGroup?.get('answer')?.setValue('');

            answerActionPanel && !this.isElementInViewport(answerActionPanel, true) && window.scrollTo({
                top: ((answerActionPanel?.lastElementChild as HTMLElement)?.offsetTop ?? 0) + 60,
                left: 0,
                behavior: 'smooth',
            });

            answerInput && answerInput?.focus();
        }, 100);
    }

    onActionAnswer(event: Event, questionGroup: FormGroup, action: ('inactive'|'active'|'delete'|'correct'), index: number = 0): boolean {
        event?.preventDefault();

        if (action === 'correct') {
            (questionGroup?.get('answers') as FormArray)?.controls.forEach((item, i) => {
                item.get('is_correct')?.setValue(i === index);
            });
        } else if (action === 'delete') {
            this.answerItemRemove(questionGroup, index);
        } else {
            (questionGroup?.get('answers') as FormArray)?.at(index).get('status')?.setValue(action === 'active');
        }

        this.checkAndUpdateCorrectAnswer(questionGroup);

        return false;
    }

    onExportAction(event?: {action?: string }): void {
        if (event?.action === 'close') {
            this.showExport = false;
        }
    }

    onDeleteConfirmAction(action: string): void {
        this.showDelete = false;

        if (action === 'yes' && this.id) {
            const subscription = this.testService.delete(this.id).subscribe(data => {
                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно изтрит тест!'),
                });
                const path = this.groupId ? `/groups/${this.groupId ?? 0}` : `/courses/${this.courseId ?? 0}`

                this.router.navigateByUrl(path);
            });
            this.subscriptions.push(subscription);
        }
    }

    protected isElementInViewport(element?: HTMLElement, partiallyVisible = false): boolean {
        const { top, left, bottom, right } = element?.getBoundingClientRect() ?? {top: 0, left: 0, bottom: 0, right: 0};
        const { innerHeight, innerWidth } = window;

        return !partiallyVisible
            ? top >= 0
                && left >= 0
                && bottom <= (innerHeight - 100)
                && right <= innerWidth
            : (
                    (top > 0 && top < (innerHeight - 100))
                    || (bottom > 0 && bottom < (innerHeight - 100))
                ) && (
                    (left > 0 && left < innerWidth)
                    || (right > 0 && right < innerWidth)
                )
        ;
    }

    onExamsListMore(additional: {q: string, reset: boolean}): void {
        if (additional.reset) {
            this.examsFilter.page = 1;
        }

        this.examsFilter.q = additional.q;
        this.getExamsList(!additional.reset);
    }

    onCopyExamAction(event: {action: string}) {
        if (event?.action === 'close') {
            this.showCopyFromDialog = false;
        }
    }

    onSelectedExamToCopy(selected?: number[]): void {
        this.showCopyFromDialog = false;
        this.fetchExamDetails(selected?.[0] ?? 0, true);
    }

    protected getExamsList(appendData?: boolean): void {
        if (this.loading) {
            return;
        }

        if (appendData && this.exams?.data?.length) {
            if (this.exams?.reachEnd()) {
                return;
            }
            this.examsFilter.page = this.exams?.nextPage();
        } else if (!this.exams?.data?.length) {
            this.examsFilter.page = 1;
        }

        this.loadingQueue++;
        const subscription = this.testService.getList(this.examsFilter).subscribe({
            next: response => {
                this.loadingQueue--;

                if (appendData && this.exams) {
                    response.data = [
                        ...this.exams?.data ?? [],
                        ...response?.data ?? [],
                    ];
                }

                this.exams = response;
                this.examsList = this.exams?.data?.map(item => {
                    return {
                        value: item.id,
                        label: item.name,
                        subtitle: [
                            item?.course,
                            item?.profession,
                            item?.specialty,
                            item.status !== 'active' ? translate('Неактивен') : null,
                        ].filter(item => item).join(' / '),
                        description:
                            item?.type?.description,
                        disabled: item.id === this.course?.id,
                    } as ModalItemsChooseComponentItem;
                })?.slice() ?? [];
            },
            error: error => {
                this.loadingQueue--;
                this.examsList = [];
            }
        });
        this.subscriptions.push(subscription);

    }
}
