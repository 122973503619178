import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { Subscription } from 'rxjs';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { Paginate } from 'src/app/shared/models/paginate.model';
import { Payment } from 'src/app/shared/models/payment.model';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { I18nService } from 'src/app/shared/services/i18n.service';
import { PaymentService, PaymentServiceListFilter } from 'src/app/shared/services/payment.service';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'sidebar-payments',
    templateUrl: './payments.component.html',
    styleUrls: ['./payments.component.scss']
})
export class SidebarPaymentsComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.payments;
    protected readonly CACHING_MAX_TIME: number = environment.caching.sidebar;

    protected subscriptions: Subscription[] = [];

    @Input() headline: string|null = null;
    @Input() noResult: string|null = null;
    @Input() studentsList: boolean = false;
    @Input() group: number|null = null;
    @Input() allPaymentsUrl: string|null = null;
    @Input() allPaymentsUrlParams: any|null = null;

    loading: boolean = false;
    paymentsFilter: PaymentServiceListFilter = {
        limit: this.PAGINATION_LIMIT,
        page: 1,
        status: 'unpaid',
        include: 'group.course,user,type',
        group: null,
    };
    paymentsData: Paginate<Payment> = new Paginate<Payment>();
    showPayment: boolean = false;
    payment?: Payment|null = null;
    language: string = '';
    company: Franchise|null = null;
    hide: boolean = false;

    constructor(
        protected router: Router,
        protected paymentService: PaymentService,
        protected i18nService: I18nService,
        protected franchiseService: FranchiseService,
    ) { }

    ngOnInit(): void {
        this.getPayments();

        const subscription = this.franchiseService?.getCompanyDetails().subscribe(data => {
            this.company = data;
            this.hide = !!this.company?.isPreference('mainmenu.hide.payments', true);
        });
        this.subscriptions.push(subscription);
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    getPayments(withCaching: boolean = true): void {
        this.loading = true;
        const cache = this.CACHING_MAX_TIME + (withCaching ? 0 : 0.1); // 0.1 force clearing the cache

        this.group && (this.paymentsFilter.group = this.group);

        const subscription = this.paymentService.getPayments(this.paymentsFilter, cache).subscribe({
            next: (data: any) => {
                this.loading = false;
                this.paymentsData = data;
            },
            error: error => {
                this.loading = false;
            }
        });
        this.subscriptions.push(subscription);

        const i18nSubscription = this.i18nService.onLocaleChanged().subscribe(locale => {
            this.language = locale.language;
        });
        this.subscriptions.push(i18nSubscription);
    }

    onShowPayment(event: Event, payment: Payment): void {
        event?.preventDefault();

        if (this.studentsList) {
            this.router.navigate(['/payments'], {queryParams: {q: payment?.user?.getFullName()}});
            return;
        }

        this.showPayment = true;
        this.payment = payment
    }

    onPaymentAction(event?: {action?: string, paid?: boolean}): void {
        if (event?.action === 'close') {
            this.showPayment = false;
        }

        // refresh
        this.getPayments(false);
    }
}
