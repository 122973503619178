<p-dialog
    [visible]="true"
    (visibleChange)="onCloseAction($event)"
    [closable]="true"
    [draggable]="false"
    [modal]="true"
    [style]="{'min-width': '40vw', 'width': '991px', 'max-width': '90vw'}"
    styleClass="mx-3 mx-sm-0"
    [header]="mode === 'add' ? ('Нов модул' | transloco) : ('Редактиране на модул' | transloco)"
>
    <div class="project container">
        <div class="row">
            <div class="col-12">
                <form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" autocomplete="off" class="standard-form">
                    <div class="row justify-content-center">
                        <div class="col-12 p-4">
                            <div class="row">
                                <div class="col-12 mb-3">
                                    <label for="number">{{ 'Номер:' | transloco }}</label>

                                    <input  requiredField requiredFieldAttribute="number"
                                            type="text"
                                            class="form-control"
                                            id="number"
                                            [placeholder]="'напр. 1.2.3'|transloco"
                                            formControlName="number"
                                            [class.is-invalid]="errors?.number?.status === 'INVALID'"
                                    />
                                    <div class="invalid-feedback" *ngIf="errors?.number?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.number?.errors?.maxlength">{{ 'Полето надвишава 10 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.number?.errors?.any">{{ errors?.number?.errors?.any }}</div>
                                </div>
                                <div class="col-12 mb-3">
                                    <label for="title">{{ 'Наименование:' | transloco }}</label>

                                    <input  requiredField requiredFieldAttribute="title"
                                            type="text"
                                            class="form-control my-2"
                                            id="title"
                                            [placeholder]="'напр. Въведение в Java' | transloco"
                                            formControlName="title"
                                            [class.is-invalid]="errors?.title?.status === 'INVALID'"
                                    />
                                    <div class="invalid-feedback" *ngIf="errors?.title?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.title?.errors?.maxlength">{{ 'Полето не трябва да надвишава 150 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.title?.errors?.any">{{ errors?.title?.errors?.any }}</div>
                                </div>

                                <div class="col-12 mb-3">
                                    <label for="description">{{ 'Описание:' | transloco }}</label>

                                    <textarea requiredField requiredFieldAttribute="description"
                                                class="form-control"
                                                id="description"
                                                formControlName="description"
                                                [attr.disabled]="loading ? true : null"
                                                [class.is-invalid]="errors?.description?.status === 'INVALID'"
                                    ></textarea>
                                    <div class="invalid-feedback" *ngIf="errors?.description?.errors?.required">{{ 'Задължително поле' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.description?.errors?.maxlength">{{ 'Не може да е повече от 65535 символа' | transloco }}</div>
                                    <div class="invalid-feedback" *ngIf="errors?.description?.errors?.any">{{ errors?.description?.errors?.any }}</div>

                                </div>
                            </div>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>

    <ng-template pTemplate="footer">
        <div class="row">
            <div class="col-sm-6 col-12">
                <div class="form-check form-switch d-flex gap-2 ms-3">
                    <form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" autocomplete="off" class="standard-form">
                        <input  type="checkbox"
                                role="switch"
                                id="status"
                                class="form-check-input"
                                formControlName="status"
                                [class.is-invalid]="form.controls['status'].invalid && form.controls['status'].touched"
                        />
                        <label for="status" class="form-check-label">{{ 'Активен статус' | transloco }}</label>
                    </form>
                </div>
            </div>

            <div class="col-sm-6 col-12 d-flex justify-content-end pt-sm-0 pt-2">
                <button type="button"
                    class="btn btn-primary"
                    [disabled]="loading"
                    (click)="onSubmit($event)"
                >
                    <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                    <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                    {{ "Запиши" | transloco }}

                </button>

                <button type="button "
                        class="btn btn-secondary"
                        [disabled]="loading"
                        (click)="onCloseAction($event)"
                >
                    <i *ngIf="!loading" class="fa-solid fa-xmark fa-fw"></i>

                    {{ 'Отказ' | transloco }}

                </button>
            </div>
        </div>
    </ng-template>
</p-dialog>
