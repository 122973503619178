import { AfterViewInit, Component, ElementRef, Input, OnDestroy, OnInit } from '@angular/core';

declare var bootstrap: any;

@Component({
    selector: 'button-info',
    templateUrl: './button-info.component.html',
    styleUrls: ['./button-info.component.scss']
})
export class ButtonInfoComponent implements OnInit, OnDestroy, AfterViewInit {
    protected popoverList: any[] = [];

    @Input() content: string  = '';

    constructor(
        private elementRef: ElementRef,
    ) { }

    ngOnInit(): void { }

    ngOnDestroy(): void {
        this.popoverList?.forEach(popover => {
            popover.dispose();
        });
    }

    ngAfterViewInit(): void {
        const popoverElements = this.elementRef?.nativeElement?.querySelectorAll('[data-bs-toggle="popover"]');
        this.popoverList = Array.from(popoverElements ?? [])?.map(element => new bootstrap.Popover(element));
    }

}
