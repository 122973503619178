import {Component, Input, Output, EventEmitter, TemplateRef, ViewChild, ContentChildren} from '@angular/core';

@Component({
    selector: 'subtab',
    templateUrl: 'subtab.component.html',
    styleUrls: [ 'subtab.component.scss' ]
})
export class SubTabComponent {
    protected innerLabel: string = '';
    protected innerUnique: string = Math.random().toString(36).substring(2, 2 + 9);
    protected innerId: string = '';
    protected innerHref: string|null = null;
    protected isHrefSet: boolean = false;

    @ViewChild(TemplateRef, {static: false}) content?: TemplateRef<any>;

    @Output() tabChange: EventEmitter<SubTabComponent> = new EventEmitter();

    @Input() active: boolean = false; // set active state
    @Input() reload: boolean|null = null; // force "reload" tab when clicked again; if it is null, can be overwritten by TabsComponent's reload param
    @Input() // set tab label/title
    set label(value: string) {
        if (this.innerLabel !== value) {
            this.innerLabel = value;

            if (!this.id.length) {
                this.id = this.getSnakeCase(value);
            }
        }
    }
    get label(): string {
        return this.innerLabel;
    }

    set unique(value) { } // disable set the unique string
    get unique() {
        return this.id + '.' + this.innerUnique;
    }

    @Input()  // unique snake-case name of the tab, used for URLs and comparisons
    set id(value: string) {
        if (this.innerId !== value) {
            this.innerId = value;
            if (this.innerHref === null || !this.isHrefSet) {
                this.innerHref = this.innerId;
            }
        }
    }
    get id(): string {
        return this.innerId;
    }

    @Input()
    set href(value: string|null) {
        if (this.innerHref !== value) {
            this.innerHref = value;
            this.isHrefSet = true;
        }
    }
    get href(): string|null {
        return this.innerHref;
    }

    constructor() {
    }

    /**
     * Get snake-case from SnakeCase
     * @param name An uppercased name
     */
    protected getSnakeCase(name: string): string {
        return name.replace(/[^a-z]/ig, '').split(/(?=[A-Z])/).join('-').toLowerCase();
    }
}
