import { Component, EventEmitter, Input, Output } from '@angular/core';
import { Poll } from '../../models/polls/poll.model';

@Component({
  selector: 'modal-poll-list',
  templateUrl: './modal-poll-list.component.html',
  styleUrls: ['./modal-poll-list.component.scss']
})
export class ModalPollListComponent {

    @Input() polls: Poll[] | null = null;

    @Output() onSelectedPoll: EventEmitter<Poll> = new EventEmitter();
    @Output() onCloseAction: EventEmitter<Event> = new EventEmitter();

    visible: boolean = false;

    constructor() { }

    ngOnInit(): void {
    }

    onClose(event?: any): void {
        if(!event) {
            this.onCloseAction.emit(event);
        }
    }

    onSelectPoll(event?: { value?: Poll }) {
        this.onSelectedPoll.emit(event?.value);
    }
}
