import { Component, Input, OnInit, EventEmitter, Output } from '@angular/core';
import { Router } from '@angular/router';
import { CourseShort } from '../../models/course-short.model';
import { FranchiseCourse } from '../../models/franchise-course.model';
import { Course } from '../../models/course.model';
import { Franchise } from '../../models/franchise.model';
import { FranchiseShort } from '../../models/franchise-short.model';
import { FranchiseService } from '../../services/franchise.service';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';

@Component({
    selector: 'card-course',
    templateUrl: './card-course.component.html',
    styleUrls: ['./card-course.component.scss']
})
export class CardCourseComponent implements OnInit {
    protected innerPageUrl: string = '';
    protected innerPartner: Franchise|FranchiseShort|null = null;

    course: Course = new Course;
    franchiseCourse?: FranchiseCourse;
    groupCount: number|string = 0;
    baseEditUrl: string = '';
    baseViewUrl: string = '';
    landingPageUrl: string|null = null;
    user: User = new User;

    @Input('course')
    set courseValue(val: Course|FranchiseCourse|CourseShort|undefined) {
        if (this.course !== val) {
            if (val instanceof Course) {
                this.course = Course.fromJson(val);
                this.course.id = val.id;
            } else if (val instanceof CourseShort) {
                this.course = Course.fromJson(val);
            } else if (val instanceof FranchiseCourse) {
                this.franchiseCourse = val;

                this.course = Course.fromJson(val?.course);
                this.course.status = val?.status;
                this.course.id = val.id;

                this.course.url = val?.url ?? '';
                this.groupCount = val?.groups_count ?? '';
            }
        }
    }
    get courseValue(): Course|undefined {
        return this.course ?? undefined;
    }

    @Input() short: boolean = false;
    @Input() mode: ('view'|'edit') = 'view';
    @Input() baseUrl: string = '';

    @Input()
    set partner(val: Franchise|FranchiseShort|null) {
        if (this.innerPartner !== val) {
            this.innerPartner = val;

            this.computeLandingPage();
        }
    }
    get partner(): Franchise|FranchiseShort|null {
        return this.innerPartner;
    }

    @Input() partnerPrimary: boolean = false;

    @Input()
    set pageUrl(val: string) {
        if (this.innerPageUrl !== val) {
            this.innerPageUrl = val;

            this.computeLandingPage();
        }
    }
    get pageUrl(): string {
        return this.innerPageUrl;
    }

    @Output() onRemove: EventEmitter<{course: number, showRemoveCourse: boolean}> = new EventEmitter();

    constructor(
        protected router: Router,
        protected franchiseService: FranchiseService,
        protected authService: AuthService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;

        if (this.user?.can(['admin', 'marketing'])) {
            this.baseEditUrl = '/partners/' + this.partner?.id;
            this.baseViewUrl = this.baseEditUrl;
        }

        this.baseEditUrl += '/courses/' + this.course?.id + '/edit';
        this.baseViewUrl += '/courses/' + this.course?.id;
    }

    preventPropagation(event?: MouseEvent): boolean {
        event?.stopPropagation();
        return true;
    }

    removeCourse(event:Event,courseId:number) {
        event.preventDefault();
        event.stopPropagation();
        this.onRemove.emit({
            course: courseId,
            showRemoveCourse: true
        })
    }

    protected computeLandingPage() {
        this.landingPageUrl = this.partner
            ? this.franchiseService.getLandingPageFullUrl(this.partner, this.pageUrl ?? '', this.course?.url ?? '', this.franchiseCourse)
            : '';
    }
}
