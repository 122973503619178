<div *ngIf="certificate" class="accordion accordion-flush">
    <div>
        <a *ngIf="isLink" class="accordion-item" [routerLink]="'/certificates/details/' + certificate?.uuid">
            <ng-container *ngTemplateOutlet="headline"></ng-container>
        </a>

        <div *ngIf="!isLink" class="accordion-item">
            <ng-container *ngTemplateOutlet="headline"></ng-container>
        </div>

        <loading [loading]="loading" [hasData]="(certificate?.id ?? 0) > 0" />

        <div *ngIf="!isLink"
            [attr.id]=" isExpandable ? 'detail'+certificate?.group?.id : certificate?.group?.id"
            [ngClass]="isExpanded ? 'accordion-collapse collapse show' : 'accordion-collapse collapse'"
        >
            <div class="accordion-body">
                <div class="row">
                    <div class="col-lg-4 col-xl-5 ">
                        <div *ngIf="(certificate?.thumbnails?.length ?? 0) > 0; else preview">
                            <p-carousel [value]="certificate?.thumbnails ?? []"
                                        [numVisible]="1"
                                        [numScroll]="1"
                                        [circular]="(certificate?.thumbnails?.length ?? 0) > 1"
                                        [showNavigators]="(certificate?.thumbnails?.length ?? 0) > 1"
                                        class="certificate-carousel"
                            >
                                <ng-template let-slide pTemplate="item">
                                    <a [href]="certificate?.url" target="_blank">
                                        <img [src]="slide" alt="" width="100%">
                                    </a>
                                </ng-template>
                            </p-carousel>
                        </div>
                        <ng-template #preview>
                            <img [src]="certificate?.group?.course?.image" [alt]="certificate?.group?.course?.title" width="100%">
                        </ng-template>
                    </div>
                    <div class="col-lg-8 col-xl-7">
                        <div class="title-text">
                            <h3>{{ 'Завършени модули в текущия курс:' | transloco }}</h3>
                            <div class="separator d-flex align-items-center">
                                <span></span>
                                <i class="fa-solid fa-ellipsis mx-2"></i>
                            </div>
                        </div>
                        <div class="slide-box" [ngClass]="{'module-slide-box': slides.length > 1}">
                            <div *ngFor="let modules of slides[currentSlideIndex];">
                                <div *ngFor="let module of modules">
                                    <module-box [module]="module"></module-box>
                                </div>
                            </div>

                        </div>
                        <div class="d-flex justify-content-end px-2">
                            <button class="btn btn-primary mx-2" *ngIf="currentSlideIndex > 0 " (click)="backSlide()">
                                <i class="fa-sharp fa-solid fa-angle-left"></i>
                            </button>
                            <button class="btn btn-primary"
                                *ngIf="slides.length > 1 && currentSlideIndex < (slides.length -1)" (click)="nextSlide()">
                                <i class="fa-solid fa-angle-right"></i>
                            </button>
                        </div>
                    </div>
                    <div class="col" >
                        <div class="actions">
                            <div>
                                <a [href]="certificate?.url" class="btn btn-primary btn-lg shadow" target="_blank"
                                    download="Certificate.pdf">
                                    <i class="fa-solid fa-cloud-arrow-down"></i>
                                    <span class="mx-2 text-md-uppercase">{{ 'Изтегли' | transloco }}</span>
                                </a>
                            </div>

                            <div class="mt-3 ms-4">
                                <h5 class="title-secondary">
                                    {{ 'Сподели с приятели' | transloco }}
                                </h5>
                                <div class="flex-center">
                                    <a [href]="facebookLink" class="btn btn-secondary btn-lg custom" target="_blank">
                                        <i class="fab fa-facebook-square"></i>
                                        <span class="mx-2">Facebook</span>
                                    </a>
                                    <a [href]="linkedInLink" class="btn btn-secondary btn-lg custom mx-2" target="_blank">
                                        <i class="fab fa-linkedin"></i>
                                        <span class="mx-2">Linkedin</span>
                                    </a>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ng-template #headline>
    <div class="accordion-header view">
        <div [ngClass]="(isExpanded ? 'certificate-box flex-center accordion-button collapsed single' : 'certificate-box flex-center accordion-button collapsed')
        + (profileView ? ' profile-view' : '')"
        data-bs-toggle="collapse" [attr.data-bs-target]="'#detail'+certificate?.group?.id" aria-expanded="true" [attr.aria-controls]="certificate?.group?.id">
            <div class="flex-center header">
                <img [src]="certificate?.group?.course?.logo" [alt]="certificate?.group?.course?.title" />
                <div class="certificate-details">
                    <h4>{{ certificate?.group?.course?.title }}</h4>
                    <h5 *ngIf="user && (user | roleAny:'admin':'partner':'marketing')" class="mb-0">{{ certificate?.user | userName }}</h5>
                    <small *ngIf="user && (user | roleAny:'admin':'partner':'marketing')" class="my-0 small d-block text-muted">
                        №{{ certificate?.number }} / {{ certificate?.created | dateLocale:'shortDate' }}
                    </small>
                </div>
            </div>

            <div class="box-info">
                <h5>
                    {{ certificate?.group?.title }}
                </h5>
                <h6>
                    {{ certificate?.group?.date_start | dateLocale:'shortDate'}}
                    -
                    {{ certificate?.group?.date_to | dateLocale:'shortDate'}}
                </h6>
            </div>

            <menu-actions   *ngIf="editButton"
                            [actions]="menuActions"
                            (buttonClicked)="onButtonClicked($event)"
                            [roles]="['admin','partner']"
            ></menu-actions>
        </div>
    </div>
</ng-template>

<modal-confirm *ngIf="showUpdate"
               [body]="'Желаете ли да актуализирате този сертификат?' | transloco"
               [headline]="'Актуализиране на сертификат?' | transloco"
               [skippable]="true"
               (onAction)="onUpdateConfirmAction($event)"
></modal-confirm>

<modal-confirm *ngIf="showDelete"
               [body]="'Желаете ли да премахнете този сертификат?' | transloco"
               [headline]="'Премахване на сертификат?' | transloco"
               [skippable]="true"
               (onAction)="onDeleteConfirmAction($event)"
></modal-confirm>
