import { Course } from "./course.model";
import { Teacher } from "./teacher.model";
import { GroupViewModule } from './group-view-module.model';
import { Live } from "./live/live.model";
import { Project } from "./project.model";
import { User } from "./user/user.model";
import { GroupEnrollment } from "./group-enrollment.model";
import { GroupInstallment } from "./group-installment.model";
import { FranchiseShort } from "./franchise-short.model";
import * as moment from 'moment';
import { FranchiseCourse } from "./franchise-course.model";
import { Notice } from "./notices/notice.model";
import { Poll } from "./polls/poll.model";
import { Room } from "./room.model";

export type GroupStatus = ('active'|'inactive');
export type GroupRegister = ('open'|'closed');


export class Group {
    id?: number;
    course?: Course;
    franchise_course?: FranchiseCourse;
    franchise?: FranchiseShort;
    title?: string;
    description?: string;
    date_start?: string;
    date_to?: string;
    date_exam?: string;
    date_certificate?: string;
    certificate_start?: number;
    certificate_prefix?: number;
    facebook?: string;
    live?: Live;
    room?: Room;
    status?: GroupStatus;
    register?: GroupRegister;
    student_cap?: number;
    terms?: string;
    expired?: boolean;
    created?: string;
    updated?: string;
    teachers?: Teacher[];
    students?: User[];
    modules?: GroupViewModule[];
    projects?: Project[];
    polls?: Poll[];
    chat?: number;
    price?: number;
    price_online?: number;
    enrollments?: GroupEnrollment[];
    installments?: GroupInstallment[];
    notices?: Notice[];
    course_conduction?: Array<('in_person'|'online')>;
    days_of_week?: Array<('monday'|'tuesday'|'wednesday'|'thursday'|'friday'|'saturday'|'sunday')>;
    parsed_date_start?: Date;
    parsed_date_to?: Date;
    parsed_date_exam?: Date;
    parsed_date_certificate?: Date;

    static fromJson(fields: any): Group {
        let item = Object.assign(new this(), fields);

        item.course = Course.fromJson(fields?.course);
        item.franchise_course = FranchiseCourse.fromJson(fields?.franchise_course);
        item.franchise = FranchiseShort.fromJson(fields?.franchise);
        item.live = fields?.live && Live.fromJson(fields?.live);
        item.room = fields?.room && Room.fromJson(fields?.room);
        item.teachers = fields?.teachers?.map((teacher: any) => Teacher.fromJson(teacher));
        item.students = fields?.students?.map((student: any) => User.fromJson(student))?.sort((a: User, b: User) => {
            let properties: Array<keyof User> = ['name', 'surname', 'lastname', 'email', 'id'];
            let compare = 0;

            for (let property of properties) {
                if ((compare = (('' + (a[property] as string))?.localeCompare('' + (b[property] as string) ?? '') ?? 0)) !== 0) {
                    return compare;
                }
            }
            return compare;
        });

        item.modules = fields?.modules?.map((module: any) => GroupViewModule.fromJson(module));
        item.projects = fields?.projects?.map((item: any) => Project.fromJson(item));
        item.enrollments = fields?.enrollments?.map((enrollment: any) => GroupEnrollment.fromJson(enrollment));
        item.installments = fields?.installments?.map((installment: any) => GroupInstallment.fromJson(installment));
        item.notices = fields?.notices?.map((notice: any) => Notice.fromJson(notice));
        item.polls = fields?.polls?.map((poll: any) => Poll.fromJson(poll));

        [
            'date_start',
            'date_to',
            'date_exam',
            'date_certificate'
        ].forEach(name => {
            item['parsed_' + name] = moment.utc(fields?.[name], true);
            item['parsed_' + name] =  item['parsed_' + name]?.isValid() ? item['parsed_' + name]?.toDate() : null;
        });

        return item;
    }

    static sortGroupsByEarlyDateAndPrice(groups: Group[]): Group[] {
        const currentDate = new Date().getTime();

        return groups?.map((group) => {
            const filteredEnrollments = group.enrollments
                ?.filter((item) => {
                    const dateValue = item.date ? new Date(item.date).getTime() : 0;
                    return currentDate < dateValue;
                })
                ?.sort((a, b) => {
                    const dateA = a.date ? new Date(a.date).getTime() : 0;
                    const dateB = b.date ? new Date(b.date).getTime() : 0;
                    return dateA - dateB;
                });

            const earlyDateAndPrice = filteredEnrollments?.length
                ? { price: Number(filteredEnrollments[0].price), date: filteredEnrollments[0].date || '' }
                : { price: Number(group.price), date: group.date_start || '' };

            const parsedDateStart =  moment.utc(earlyDateAndPrice.date, true);

            return {
                ...group,
                price: earlyDateAndPrice.price,
                start: earlyDateAndPrice.date,
                parsed_date_start: parsedDateStart?.isValid() ? parsedDateStart?.toDate() : new Date,
            };
        }).sort((a, b) => (new Date(a.start ?? '')).getTime() - (new Date(b.start ?? '')).getTime());
    }
}
