import { Injectable } from '@angular/core';
import { ApiService } from './api.service';
import { FilterSort } from '../models/filter-sort.model';
import { map, Observable } from 'rxjs';
import { Paginate } from '../models/paginate.model';
import { Course, CourseStatus } from '../models/course.model';
import { CourseDesignNested } from '../models/course/course-design-nested';
import { CourseSpotlight } from '../models/course/course-spotlight.model';
import { Module, ModuleStatus } from '../models/module.model';
import { Exercise } from '../models/exercise.model';
import { Theory } from '../models/theory.model';
import { Methodology } from '../models/methodology.model';
import { CourseDesign } from '../models/course-design.model';
import { CourseCategoryTree } from '../models/course/course-category-tree.model';

export type CourseServiceIndexSortType = (
     'id'
    | 'title'
    | 'image'
    | 'description'
    | 'fee'
    | 'price'
    | 'program'
    | 'status'
    | 'url'
    | 'weeks'
    | 'position'
    | 'starting'
    | 'created'
    | 'updated'
    | 'modules_count'
);

export type CourseServiceInclude = (
    | 'modules'
    | 'design'
    | 'spotlights'
    | 'topics'
    | 'previews'
);

export interface CourseServiceIndex  {
    limit?: number;
    page?: number;
    q?: string;
    sort?: CourseServiceIndexSortType|FilterSort<CourseServiceIndexSortType>[];
    include?: string|CourseServiceInclude|CourseServiceInclude[];
    groups_availability?: ('available'|'unavailable');
    status?: ('inactive'|'active'|null);
    skip_for_franchise?: number;
    design?: number|null;

    filter?: {
        [name: string]: {[operator: string]: number|string|boolean}|number|string|boolean;
    };

};

export interface CourseServiceInput {
    title: string;
    description?: string;
    url?: string;
    price?: number;
    presents?: boolean;
    status?: CourseStatus;
    duration?: number;
    starting?: string;
    image?: string;
    logo?: string;
    image_share?: string;
    badge?: string;
    badge_alt?: string;
    design?: number;
    available?: boolean;
    level?: number;
    position?: number;
    position_footer?: number;
    spotlights?: number[];
    topics?: Array<{
        title?: string;
        hours?: number;
        status?: CourseStatus;
    }>;
    previews?: Array<{
        title?: string;
        type?: string;
        description?: string;
    }>;
};

export interface CourseServiceModuleInput {
    number?: string;
    title?: string;
    description?: string;
    skill?: string;
    content?: string;
    status?: ModuleStatus;
    homework?: string;
    theory_teacher?: string;
    exercises?: Exercise[];
    methodologies?: Methodology[];
    theory_elements?: Theory[];
};

export interface CourseServiceFilter {
    include?: string|CourseServiceInclude|CourseServiceInclude[];
};

export interface CourseServiceCategoryInput {
    name: string;
    design?: string;
    url?: string;
    parent?: number;
    status?: ('active'|'inactive');
};

export type CourseServiceIndexCategorySortType = (
    'id'
    |'name'
    |'image'
    |'status'
    |'url'
    |'courses'
    |'parent'
);

export type CourseServiceIndexCategoryIncludeType = (
    'parent'
    | 'children'
    | 'courses'
);

export interface CourseServiceIndexCategory  {
    limit?: number,
    page?: number,
    sort?: CourseServiceIndexCategorySortType|FilterSort<CourseServiceIndexCategorySortType>[],
    q?: string;
    include?: string|CourseServiceIndexCategorySortType[];
};

@Injectable({
    providedIn: 'root'
})
export class CourseService {

    constructor(private api: ApiService) {}

    /**
     * Get list of courses
     *
     * @param filter
     * @returns
     */
    getList(filter?: CourseServiceIndex, maxCacheTime?: number): Observable<Paginate<Course>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CourseServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        if (filter?.filter && Object.keys(filter?.filter).length) {
            for (let field in filter.filter) {
                if (typeof filter?.filter[field] === 'object') {
                    for (let operator in filter.filter[field] as any) {
                        (filter as any)['filter[' + field + ']'] = operator + (filter?.filter[field] as { [key: string]: any })[operator];
                    }
                } else {
                    (filter as any)['filter[' + field + ']']  = filter?.filter[field];
                }
            }
        }

        return this.api.get('/courses', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Course>(), data);
                data.data = data.data?.map((item: any)=> Course.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get an existing course
     *
     * @param id
     * @param filter
     * @returns
     */
    getItem(id: number, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/' + id, {params: filter}).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Create new course
     *
     * @param input
     * @param filter
     * @returns
     */
    add(input: CourseServiceInput, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));
        input = {
            ...input,
            ...filter ?? {},
        };

        return this.api.post('/courses', input).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing course
     *
     * @param id
     * @param input
     * @param filter
     * @returns
     */
    update(id: number, input: CourseServiceInput, filter?: CourseServiceFilter): Observable<{data: Course}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));
        input = {
            ...input,
            ...filter ?? {},
        };

        return this.api.post('/courses/' + id, input).pipe(
            map(data => {
                data.data = Course.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete an existing course without having any groups attached
     *
     * @param id
     * @returns
     */
    delete(id: number): Observable<{data: any}> {
        return this.api.delete('/courses/' + id);
    }

    /**
     * Get an existing course
     *
     * @returns
     */
    getDesignList(): Observable<{data: CourseDesignNested[]}> {
        return this.api.get('/courses/designs/list').pipe(
            map(data => {
                data.data = data?.data?.map((item: any) => CourseDesignNested.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get an existing course
     *
     * @returns
     */
    getSpotlightList(): Observable<{data: CourseSpotlight[]}> {
        return this.api.get('/courses/spotlights/list').pipe(
            map(data => {
                data.data = data?.data?.map((item: any) => CourseSpotlight.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get list of courses
     *
     * @param filter
     * @returns
     */
    getModulesList(id: number, filter?: CourseServiceIndex): Observable<Paginate<Module>>  {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CourseServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/' + id + '/modules', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<Module>(), data);
                data.data = data.data?.map((item: any)=> Module.fromJson(item));
                return data;
            })
        );
    }

    /**
     * Get a single module within course
     *
     * @param id
     * @param moduleId
     * @returns
     */
    getModule(id: number, moduleId: number): Observable<{data: Module}> {
        return this.api.get('/courses/' + id + '/modules/' + moduleId).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Create a new course module
     *
     * @param id
     * @param input
     * @returns
     */
    addModule(id: number, input: CourseServiceModuleInput): Observable<{data: Module}> {
        return this.api.post('/courses/' + id + "/modules", input).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing module
     *
     * @param id
     * @param moduleId
     * @param input
     * @returns
     */
    updateModule(id: number, moduleId: number, input: CourseServiceModuleInput): Observable<{data: Module}> {
        return this.api.post('/courses/' + id + '/modules/' + moduleId, input).pipe(
            map(data => {
                data.data = Module.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete an existing module
     *
     * @param id
     * @param moduleId
     * @returns
     */
    deleteModule(id: number, moduleId: number): Observable<{data: any}> {
        return this.api.delete('/courses/' + id + '/modules/' + moduleId);
    }

    /**
     * Copy modules from source course id
     *
     * @param id
     * @param moduleId
     * @returns
     */
    copyModules(id: number, sourceCourseId: number): Observable<{data: any}> {
        return this.api.post('/courses/' + id + '/modules/copies/' + sourceCourseId);
    }

    /**
     * Get list of category
     * @returns
     */
    categoriesGetListNested(): Observable<Paginate<CourseDesignNested>> {
        return this.api.get('/courses/categories/list').pipe(
            map(data => {
                data.data = data.data?.map((item: any)=> CourseDesignNested.fromJson(item));
                return data;
            })
        )
    }

    /**
     * Get list of category
     * @param filter
     * @returns
     */
    categoriesGetList(filter?: CourseServiceIndexCategory): Observable<Paginate<CourseDesign>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as CourseServiceIndexCategorySortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/categories', {params: filter}).pipe(
            map(data => {
                data = Object.assign(new Paginate<CourseDesign>(), data);
                data.data = data.data?.map((item: any)=> CourseDesign.fromJson(item));
                return data;
            })
        )
    }

    /**
     * Get a single category
     * @param id
     * @returns
     */
    categoriesGetItem(id: number, filter?: {include?: string|CourseServiceIndexCategoryIncludeType[]}): Observable<{data: CourseDesign}> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/courses/categories/' + id, {params: filter}).pipe(
            map(data => {
                data.data = CourseDesign.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Add a new category
     * @returns
     */
    categoriesAdd(data: CourseServiceCategoryInput): Observable<{data: CourseDesign}> {
        return this.api.post('/courses/categories', data).pipe(
            map(data => {
                data.data = CourseDesign.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Update an existing category
     * @returns
     */
    categoriesEdit(id: number, data: CourseServiceCategoryInput): Observable<{data: CourseDesign}> {
        return this.api.post('/courses/categories/' + id, data).pipe(
            map(data => {
                data.data = CourseDesign.fromJson(data?.data);
                return data;
            })
        );
    }

    /**
     * Delete a category
     * @param id
     * @returns
     */
    categoriesDelete(id: number):  Observable<any> {
        return this.api.delete('/courses/categories/' + id);
    }

    /**
     * Edit position of Multiple Items
     * @param data
     * @returns
     */
    categoriesSort(parent: number, data: Array<{id: number, position: number}>): Observable<{data: any[]}> {
        return this.api.patch("/courses/categories/sort", {parent, data});
    }

    /**
     * Get Tree of categories, subcategories and pages
     *
     * @returns
     */
    categoriesGetTree(): Observable<{data: CourseCategoryTree[]}> {
        return this.api.get('/courses/categories/tree').pipe(
            map((response: any) => {
                response.data = response?.data?.map((item: any) => CourseCategoryTree.fromJson(item));
                return response;
            })
        );
    }

    /**
     * Update a Tree of categories, subcategories and pages
     *
     * @param data
     * @returns
     */
    categoriesUpdateTree(data: CourseCategoryTree[]): Observable<{data: CourseCategoryTree[]}> {
        return this.api.post('/courses/categories/tree', data).pipe(
            map((response: any) => {
                response.data = response?.data?.map((item: any) => CourseCategoryTree.fromJson(item));
                return response;
            })
        );
    }

}

