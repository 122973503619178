import { Component, EventEmitter, OnDestroy, OnInit, Output } from '@angular/core';
import { User } from 'src/app/shared/models/user/user.model';
import { Input } from '@angular/core';
import { Group } from '../../models/group.model';
import { GroupService } from '../../services/group.service';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { environment } from 'src/environments/environment';

@Component({
    selector: 'card-group-small',
    templateUrl: './card-group-small.component.html',
    styleUrls: ['./card-group-small.component.scss'],
})
export class CardGroupSmallComponent implements OnInit, OnDestroy {
    protected readonly PAGINATION_LIMIT: number = environment.pagination.default;

    protected subscriptions: Subscription[] = [];

    @Input() group?: Group|null;
    @Input() user?: User|null;
    @Input() withEdit: boolean = true;
    @Input() withRemove: boolean = true;
    @Input() isCompetition: boolean = false;

    @Output() onDeleted: EventEmitter<Group|null> = new EventEmitter();

    loading: boolean = true;
    isLink:boolean = true;
    roles: number[] = [];

    rowHighlights: string[] = [];
    status: string = '';
    current: number = 0;
    perPage: number = 20;

    showConfirm: boolean = false;

    constructor(
        private groupService: GroupService,
        private messageService: MessageService,
    ) {

    }

    ngOnInit(): void {

    }

    ngOnDestroy(): void {
        this.subscriptions?.map(item => item?.unsubscribe());
    }

    preventDefault(event: MouseEvent): void {
        event?.preventDefault();
        event?.stopPropagation();
    }

    onShowConfirm(event: Event): void {
        event?.preventDefault();
        event?.stopPropagation();

        this.showConfirm = true;
    }

    onDeleteConfirmAction(action: string): void {
        this.showConfirm = false;

        if (action === 'yes' && this.group?.id) {
            const subscription = this.groupService.deleteGroup(this.group?.id).subscribe(data => {
                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно изтритa група!'),
                });

                this.onDeleted.emit(this.group);
            });
            this.subscriptions.push(subscription);
        }
    }
}
