import { Location } from '@angular/common';
import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators, FormGroup, FormBuilder } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UploadComponentQueue } from 'src/app/groups/module/module.component';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { User } from 'src/app/shared/models/user/user.model';
import { FranchiseServiceInputFranchise } from 'src/app/shared/services/franchise.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { UserRoleName } from 'src/app/shared/models/user/user-role.model';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { translate } from '@ngneat/transloco';
import { TownService } from 'src/app/shared/services/town.service';
import { FranchisePlan } from 'src/app/shared/models/franchise-plan.model';
import { Router } from '@angular/router';
import { FranchisePreference, FranchisePreferenceType } from 'src/app/shared/models/franchise-preference.model';

interface PreferenceFieldType {
    type: FranchisePreferenceType | ('text');
    private?: boolean;
    customBoolean?: {
        enable: string;
        disable: string;
    };
};

interface PreferenceLoopItem {
    preference: string;
    attributes: PreferenceFieldType;
    type: FranchisePreferenceType | ('text');
    fieldName: string;
    fieldValue: any;
    defaultValue: boolean|number|string;
};

@Component({
    selector: 'form-partner',
    templateUrl: './form-partner.component.html',
    styleUrls: ['./form-partner.component.scss']
})
export class FormPartnerComponent implements OnInit {
    protected innerLoadingQueue: number = 0;
    protected subscriptions: Subscription[] = [];
    protected innerId: number|null = null;
    protected innerSubmit: boolean = false;

    protected preferences: {[prefName: string]:PreferenceFieldType} = {
        // Landing
        [FranchisePreference.PREFERENCE_LANDING_FOOTER_INFO]: {
            type:'string',
        },

        // Payments
        [FranchisePreference.PREFERENCE_PAYMENTS_OFFICE_STATUS]: {
            type: 'boolean',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_OFFICE_DESCRIPTION]: {
            type: 'text',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_BANK_STATUS]: {
            type: 'boolean',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_BANK_DESCRIPTION]: {
            type: 'text',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_STATUS]: {
            type: 'boolean',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_TYPE]: {
            type: 'boolean',
            customBoolean: {enable: 'live', disable: 'sandbox'}
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_SANDBOX_SID]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_SANDBOX_WALLET]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_SANDBOX_KEY]: {
            type: 'text',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_SANDBOX_CERTIFICATE]: {
            type: 'text',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_LIVE_SID]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_LIVE_WALLET]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_LIVE_KEY]: {
            type: 'text',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_MYPOS_LIVE_CERTIFICATE]: {
            type: 'text',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_STATUS]: {
            type: 'boolean',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_MODE]: {
            type: 'boolean',
            customBoolean: {enable: 'live', disable: 'sandbox'},
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_SANDBOX_CLIENT_ID]: {
            type: 'string',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_SANDBOX_CLIENT_SECRET]: {
            type:'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_LIVE_CLIENT_ID]: {
            type: 'text',
        },
        [FranchisePreference.PREFERENCE_PAYMENTS_PAYPAL_LIVE_CLIENT_SECRET]: {
            type: 'text',
            private: true,
        },


        // Certificates
        [FranchisePreference.PREFERENCE_CERTIFICATE_COURSE_TITLE]: {
            type: 'boolean',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_TEMPLATE_MULTIPAGE]: {
            type: 'boolean',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_STUDENT_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_DATE_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_COURSE_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_CERT_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_HELD_DATE_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_STUDENT_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_FONT]: {
            type: 'string',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TOPICS_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_COORDS_X]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_COORDS_Y]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_WIDTH]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_HEIGHT]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_SIZE]: {
            type: 'integer',
            private: true,
        },
        [FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_DATE_FONT]: {
            type: 'string',
            private: true,
        },

        [FranchisePreference.PREFERENCE_COMPANY_VTC_NAME]: {
            type: 'string',
            private: false,
        },
    }

    partner: User = new User;
    role: UserRoleName = 'partner';
    user: User = new User;
    form: UntypedFormGroup|null = null;
    preferenceControls: {[key: string]: UntypedFormControl} = {};
    photo: any = null;
    logo: {path?: string, url?: string} = {};
    icon: {path?: string, url?: string} = {};
    imageShare: {path?: string, url?: string} = {};
    homepageBackground: {path?: string, url?: string} = {};
    errors: any = {};
    message: string = '';
    messageType: ('error'|'success') = 'error';
    blogIssue: string|null = null;
    statusList: Array<{id: string, name: string}> = [{
        id: 'active',
        name: translate('Видим'),
    }, {
        id: 'inactive',
        name: translate('Скрит'),
    }];
    franchisesList: Franchise[] = [];
    townList: Array<{id: string|null, name: string}> = [];
    planList: FranchisePlan[] = [];
    selectedPlan: number|null = null;
    selectedTown: number|null = null;

    firstPage: any = null;
    secondPage: any = null;
    showCertificate: boolean = false;

    messageFiles: string = '';
    messageFilesType: ('error'|'success'|'warning') = 'error';

    certificateFontOptions: Array<{value: string|null, label: string}> = [{
        value: 'bahnschrift-semi-condensed',
        label: 'Bahnschrift Semi Condensed'
    }, {
        value: 'Lumios-Marker',
        label: 'Lumios Marker'
    }];
    franchise: Franchise|null = null;
    franchisePreferences: FranchisePreference[] = [];
    existingPreferences: FranchisePreference[] = [];


    @Output('franchise') onFranchiseLoad: EventEmitter<Franchise> = new EventEmitter();

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;
    @Input() mode:('add'|'edit') = 'add';

    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    set submit(value: boolean) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = false;
            value && this.onSubmit();

            setTimeout(() => {
                this.submitChange.emit(this.innerSubmit);
            });
        }
    }
    get submit(): boolean {
        return this.innerSubmit;
    }

    @Input()
    set id(value: number|null) {
        if (this.innerId !== value) {
            this.innerId = value;
            this.innerId && this.getFranchise(this.innerId);
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            this.loadingChange.emit(this.loading);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private router: Router,
        private authService: AuthService,
        private locationService: Location,
        private messageService: MessageService,
        private apiValidation: ApiValidation,
        private franchiseService: FranchiseService,
        private townService: TownService,
        private formBuilder: FormBuilder,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser() ?? this.user;
        this.initForm();
        this.getTownList();

        if (this.user.can(['admin'])) {
            this.getFranchisePlans();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    onFilePartnerFinished({files}: {files: UploadComponentQueue[]}): void {
        if (!files || !files.length || files[0].error) {
            this.message = 'Invalid image, upload issue or file is too big';
            this.messageType = 'error';
        } else {
            this.photo = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        }
    }
    onFileFranchiseFinished({files}: {files: UploadComponentQueue[]}, type: ('icon'|'logo'|'image_share'|'homepage_background')): void {
        if (!files || !files.length || files[0].error) {
            this.message = 'Invalid image, upload issue or file is too big';
            this.messageType = 'error';
        } else if (type === 'logo') {
            this.logo = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        } else if (type === 'icon') {
            this.icon = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        } else if (type === 'image_share') {
            this.imageShare = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        } else if (type === 'homepage_background') {
            this.homepageBackground = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        }
    }

    onSubmit(event?: any): void {
        if (this.loading) {
            return;
        }

        const taxNumberFull = this.form?.get('company_tax_registration')?.value
            ? 'BG' + this.form?.get('company_tax_number')?.value
            : this.form?.get('company_tax_number')?.value ;


        if(this.mode === 'add') {
            // Partner
            this.form?.get('avatar')?.setValue((this.photo?.path || '').trim());
            this.form?.get('name')?.setValue((this.form?.get('name')?.value || '').trim());
            this.form?.get('surname')?.setValue((this.form?.get('surname')?.value || '').trim());
            this.form?.get('lastname')?.setValue((this.form?.get('lastname')?.value || '').trim());
            this.form?.get('email')?.setValue((this.form?.get('email')?.value || '').trim());
            this.form?.get('phone')?.setValue((this.form?.get('phone')?.value || '').trim());
            this.form?.get('password')?.setValue((this.form?.get('password')?.value || '').trim());
        }

        // Franchise
        this.form?.get('franchise_name')?.setValue((this.form?.get('franchise_name')?.value || '').trim());
        this.form?.get('franchise_url')?.setValue((this.form?.get('franchise_url')?.value || '').trim());
        this.form?.get('franchise_homepage')?.setValue((this.form?.get('franchise_homepage')?.value || '').trim());
        this.form?.get('franchise_address')?.setValue((this.form?.get('franchise_address')?.value || '').trim());
        this.form?.get('franchise_phone')?.setValue((this.form?.get('franchise_phone')?.value || '').trim());
        this.form?.get('franchise_email')?.setValue((this.form?.get('franchise_email')?.value || '').trim());
        this.form?.get('franchise_facebook')?.setValue((this.form?.get('franchise_facebook')?.value || '').trim());
        this.form?.get('franchise_location_url')?.setValue((this.form?.get('franchise_location_url')?.value || '').trim());
        this.form?.get('franchise_description')?.setValue((this.form?.get('franchise_description')?.value || '').trim());
        this.form?.get('franchise_town')?.setValue(this.selectedTown);
        this.form?.get('franchise_plan')?.setValue(this.selectedPlan);
        this.form?.get('logo')?.setValue((this.logo?.path || '').trim());
        this.form?.get('icon')?.setValue((this.icon?.path || '').trim());
        this.form?.get('image_share')?.setValue((this.imageShare?.path || '').trim());
        this.form?.get('franchise_homepage_background')?.setValue((this.homepageBackground?.path || '').trim());

        // Company
        this.form?.get('company_full_name')?.setValue((this.form?.get('company_full_name')?.value || '').trim());
        this.form?.get('company_address')?.setValue((this.form?.get('company_address')?.value || '').trim());
        this.form?.get('company_ceo')?.setValue((this.form?.get('company_ceo')?.value || '').trim());
        this.form?.get('company_tax_number')?.setValue((this.form?.get('company_tax_number')?.value || '').trim());

        this.franchisePreferences = [];

        for (let item of this.preferencesList()) {
            const preferenceValue = this.form?.get(item.fieldName)?.value;

            if (('' + (preferenceValue ?? ''))?.length) {
                this.franchisePreferences.push({
                    type: item.type === 'text' ? 'string' : item.type,
                    visibility: (item.attributes?.private ?? false) ? 'private' : 'public',
                    name: item.preference,
                    value: preferenceValue,
                });
            }
        }

        this.form?.updateValueAndValidity();

        if (this.form?.invalid) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            return;
        }

        if (this.showCertificate) {
            this.firstPage && this.franchisePreferences.push({
                type: 'string',
                visibility: 'public',
                name: FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_TEMPLATE,
                value: this.firstPage.path
            });

            this.secondPage && this.franchisePreferences.push({
                type: 'string',
                visibility: 'public',
                name: FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TEMPLATE,
                value: this.secondPage.path
            });
        }

        const formData: FranchiseServiceInputFranchise & {town?: null } = {
            logo: this?.form?.get('logo')?.value || "",
            icon: this?.form?.get('icon')?.value || "",
            image_share: this?.form?.get('image_share')?.value || "",
            url: this.form?.get('franchise_url')?.value ?? "",
            plan: this.selectedPlan || undefined,
            name: this.form?.get('franchise_name')?.value || undefined,
            status: !!(this.form?.get('franchise_status')?.value ?? false) ? 'active' : 'inactive',

            // @ts-ignore
            town: this.selectedTown || null, // cannot be overridden
            email: this.form?.get('franchise_email')?.value || '',
            phone: this.form?.get('franchise_phone')?.value || '',
            facebook: this.form?.get('franchise_facebook')?.value || '',
            address: this.form?.get('franchise_address')?.value || '',
            homepage: this.form?.get('franchise_homepage')?.value || '',
            homepage_description: this.form?.get('franchise_homepage_description')?.value || '',
            homepage_background: this.form?.get('franchise_homepage_background')?.value || '',
            location_url: this.form?.get('franchise_location_url')?.value || '',
            description: this.form?.get('franchise_description')?.value || '',
            company_full_name: this.form?.get('company_full_name')?.value || '',
            company_tax_number: (taxNumberFull ?? this.franchise?.company_tax_number) || '',
            company_address: this.form?.get('company_address')?.value || '',
            company_ceo: this.form?.get('company_ceo')?.value || '',

            preferences: this.showCertificate
                ? this.mergePreferences(this.existingPreferences, this.franchisePreferences)
                : this.mergePreferences(this.existingPreferences, this.franchisePreferences)
                    ?.filter(item => !item.name?.startsWith(FranchisePreference.PREFERENCE_CATEGORY_CERTIFICATES))
            ,
        };

        if (this.mode === 'add') {
            formData.administrator_user = {
                email: this.form?.get('email')?.value ?? '',
                name: this.form?.get('name')?.value ?? '',
                surname: this.form?.get('surname')?.value ?? '',
                lastname: this.form?.get('lastname')?.value ?? '',
                image: this.photo.path || '',
                phone: this.form?.get('phone')?.value ?? '',
                password: this.form?.get('password')?.value || null,
                status: !!(this.form?.get('status')?.value ?? false) ? 'active' : 'inactive',
            }
        }
        else {
            formData.administrator = this.franchise?.admin?.id;
        }

        const userAction = this.mode === 'add'
            ? this.franchiseService.add(formData)
            : this.franchiseService.updateFranchise(this?.franchise?.id ?? 0,formData);

        this.loadingQueue++;

        const userSubscription = userAction.subscribe({
            next: (data: any) => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    summary: this.mode === 'add'
                        ? translate('Успешно добавен запис!')
                        : translate('Успешно редактиран запис!')
                    ,
                    detail: data.message
                });

                if (this.mode === 'add') {
                    this.locationService.back();
                }
            },
            error: (error: any) => {
                this.loadingQueue--;

                // Rename administrator_user.{field} to administrator_user_{field} to match form field
                if (error?.error?.errors) {
                    for (let item in error?.error?.errors) {
                        let rename: string|null = null;

                        if (item.indexOf('.') !== -1) {
                            rename = item.split('.')[1] || null;
                        } else if (item.indexOf('.') === -1) {
                            rename = 'franchise_' + item;
                        }

                        if (rename) {
                            error.error.errors[rename] = error?.error?.errors[item].slice();
                            error.error.errors[rename] = error.error.errors[rename].map((validate: any) => {
                                validate.message = validate?.message?.replace('administrator user.', '');
                                return validate;
                            });
                        }
                    }
                }

                this.apiValidation.checkBadRequest(error, this.form, true).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(userSubscription);
    }

    protected initForm(): void {

        const [number, registered] = this.getTaxNumber(this.franchise?.company_tax_number);

        const franchiseControls: { [key: string]: UntypedFormControl } = {
            logo: new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(255),
            ]),
            icon: new UntypedFormControl('', [
                Validators.required,
                Validators.maxLength(255),
            ]),
            image_share: new UntypedFormControl('', [
                Validators.maxLength(255),
            ]),

            franchise_url: new UntypedFormControl(this.franchise?.url ?? '', this.user.can(['partner']) ? [] : [
                Validators.required,
                Validators.pattern(/^(localhost|(?!(\-))(?:(?:[a-zA-Z\d][a-zA-Z\d\-]{0,61})?[a-zA-Z\d]\.){1,126}(?!\d+)[a-zA-Z\d]{1,63})$/)
            ]),

            franchise_name: new UntypedFormControl(this.franchise?.name, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(100),
            ]),

            franchise_status: new UntypedFormControl(this.franchise?.id ? this.franchise?.status === 'active' : true, []),

            franchise_town: new UntypedFormControl(this?.franchise?.town?.id ?? '', []),

            franchise_email: new UntypedFormControl(this.franchise?.email ?? '', [
                Validators.required,
                Validators.maxLength(100),
                Validators.email,
            ]),
            franchise_phone: new UntypedFormControl(this.franchise?.phone ?? '', [
                Validators.maxLength(100),
            ]),
            franchise_address: new UntypedFormControl(this.franchise?.address ?? '', [
                Validators.maxLength(100),
            ]),
            franchise_homepage: new UntypedFormControl(this.franchise?.homepage ?? '', [
                Validators.maxLength(255),
            ]),
            franchise_homepage_description: new UntypedFormControl(this.franchise?.homepage_description ?? '', [
                Validators.maxLength(65535),
            ]),
            franchise_homepage_background: new UntypedFormControl(this.franchise?.homepage_background ?? '', [
                Validators.maxLength(255),
            ]),
            company_tax_registration: new UntypedFormControl(registered ?? false, []),
            company_tax_number: new UntypedFormControl(number ?? '', [
                Validators.maxLength(10),
                Validators.pattern(/^[\d]+$/),
            ]),
            franchise_facebook: new UntypedFormControl(this.franchise?.facebook ?? '', [
                Validators.maxLength(255),
            ]),
            franchise_location_url: new UntypedFormControl(this.franchise?.location_url ?? '', [
                Validators.maxLength(65535),
            ]),
            franchise_description: new UntypedFormControl(this.franchise?.description ?? '', [
                Validators.maxLength(65535),
            ]),
            company_full_name: new UntypedFormControl(this.franchise?.company_full_name ?? '', [
                Validators.maxLength(255),
            ]),
            company_address: new UntypedFormControl(this.franchise?.company_address ?? '', [
                Validators.maxLength(255),
            ]),
            company_ceo: new UntypedFormControl(this.franchise?.company_ceo ?? '', [
                Validators.maxLength(255),
            ]),
        }

        if (this.user?.role?.name !== 'partner') {
            franchiseControls['franchise_plan'] = new UntypedFormControl(this.franchise?.plan?.id, [
                Validators.required
            ]);
        }

        const userControls = {
            email: new UntypedFormControl(this.partner?.email, [
                Validators.required,
                Validators.maxLength(255),
                Validators.email,
            ]),
            phone: new UntypedFormControl(this.partner?.phone, [
                Validators.maxLength(20),
            ]),
            name: new UntypedFormControl(this.partner?.name, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            surname: new UntypedFormControl(this.partner?.surname, [
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            lastname: new UntypedFormControl(this.partner?.lastname, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            password: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),

            status: new UntypedFormControl(this.partner?.id ? this.franchise?.status === 'active' : true, []),
        }

        // init preference controls
        this.preferenceControls = {};
        for (let item of this.preferencesList()) {
            this.preferenceControls[item.fieldName] = new UntypedFormControl(item.fieldValue ?? item.defaultValue, [
                ...(item.type !== 'boolean' ? [
                    Validators.maxLength(item.type === 'string' ? 255 : 65535),
                ] : [])
            ]);
        }

        this.form = new UntypedFormGroup({
            ...(this.mode === 'add' ? userControls : {}),
            ...franchiseControls,
            ...this.preferenceControls,
        });

        this.photo = {
            url: (this.partner?.image || ''),
            path: (this.partner?.image || ''),
        };

        this.logo = {
            url: (this.franchise?.logo || ''),
            path: (this.franchise?.logo || ''),
        };

        this.icon = {
            url: (this.franchise?.icon || ''),
            path: (this.franchise?.icon || ''),
        };

        this.imageShare = {
            url: (this.franchise?.image_share || ''),
            path: (this.franchise?.image_share || ''),
        };

        this.homepageBackground = {
            url: (this.franchise?.homepage_background || ''),
            path: (this.franchise?.homepage_background || ''),
        };

        if (this.user?.role?.name === 'partner') {
            this.updateFranchiseUrl();
        }
    }

    updateFranchiseUrl(): void {
        const newUrlValue = 'updated-url-value';
        this.form?.get('franchise_url')?.setValue(newUrlValue, { emitEvent: false });
    }

    protected updateForm(): void {
        this.selectedPlan = this?.franchise?.plan?.id ?? null;
        this.selectedTown = this?.franchise?.town?.id ?? null;

        const [number, registered] = this.getTaxNumber(this.franchise?.company_tax_number);

        this.logo = {
            url: (this.franchise?.logo || ''),
            path: (this.franchise?.logo || ''),
        };

        this.icon = {
            url: (this.franchise?.icon || ''),
            path: (this.franchise?.icon || ''),
        };

        this.imageShare = {
            url: (this.franchise?.image_share || ''),
            path: (this.franchise?.image_share || ''),
        };

        this.homepageBackground = {
            url: (this.franchise?.homepage_background || ''),
            path: (this.franchise?.homepage_background || ''),
        };

        if (this.form) {
            this.form?.get('logo')?.setValue((this.franchise?.image || '').trim());
            this.form?.get('icon')?.setValue((this.franchise?.image || '').trim());
            this.form?.get('image_share')?.setValue((this.franchise?.image_share || '').trim());
            this.form?.get('franchise_url')?.setValue((this.franchise?.url || '').trim());
            this.form?.get('franchise_name')?.setValue((this.franchise?.name || '').trim());
            this.form?.get('franchise_email')?.setValue((this.franchise?.email || '').trim());
            this.form?.get('franchise_phone')?.setValue((this.franchise?.phone || '').trim());
            this.form?.get('franchise_address')?.setValue((this.franchise?.address || '').trim());
            this.form?.get('franchise_homepage')?.setValue((this.franchise?.homepage || '').trim());
            this.form?.get('franchise_homepage_description')?.setValue((this.franchise?.homepage_description || '').trim());
            this.form?.get('franchise_homepage_background')?.setValue((this.franchise?.homepage_background || '').trim());
            this.form?.get('franchise_town')?.setValue((this.franchise?.town?.id) || 0);
            this.form?.get('franchise_plan')?.setValue((this.franchise?.plan?.id) || 0);
            this.form?.get('franchise_facebook')?.setValue((this.franchise?.facebook || '').trim());
            this.form?.get('franchise_location_url')?.setValue((this.franchise?.location_url || '').trim());
            this.form?.get('franchise_description')?.setValue((this.franchise?.description || '').trim());
            this.form?.get('company_full_name')?.setValue((this.franchise?.company_full_name || '').trim());
            this.form?.get('company_tax_number')?.setValue((number || '').trim());
            this.form?.get('company_tax_registration')?.setValue((registered || false));
            this.form?.get('company_address')?.setValue((this.franchise?.company_address || '').trim());
            this.form?.get('company_ceo')?.setValue((this.franchise?.company_ceo || '').trim());
            this.form?.get('franchise_status')?.setValue(this.franchise?.status === 'active');

            // preferences
            for (let item of this.preferencesList()) {
                this.form.get(item.fieldName)?.setValue(item.fieldValue ?? item.defaultValue);
            }

            this.showCertificate = (this.franchise
                ?.preferences
                ?.filter(pref =>
                    pref.name?.startsWith(FranchisePreference.PREFERENCE_CATEGORY_CERTIFICATES)
                    && pref.value
                )?.length ?? 0) > 0;
        }

        this.franchise?.preferences?.forEach((preference: any) => {
            preference.name == FranchisePreference.PREFERENCE_CERTIFICATE_FIRST_PAGE_TEMPLATE
                && (this.firstPage = {
                    url: preference.value,
                    path: preference.value?.toString().substring(preference.value?.toString().indexOf('/uploads')),
                    title: preference.value?.toString().split('/').pop()
                });

            preference.name == FranchisePreference.PREFERENCE_CERTIFICATE_SECOND_PAGE_TEMPLATE
                && (this.secondPage = {
                    url: preference.value,
                    path: preference.value?.toString().substring(preference.value?.toString().indexOf('/uploads')),
                    title: preference.value?.toString().split('/').pop()
                });
        });

        this.form?.markAsUntouched();
    }

    protected getFranchise(id: number): void {
        this.loadingQueue++;
        const subscription = this.franchiseService.getFranchise(id).subscribe({
            next: data => {
                this.loadingQueue--;
                this.franchise = data?.data;
                this.existingPreferences = this.franchise.preferences ?? [];

                this.updateForm();

                setTimeout(() => {
                    this.franchise && this.onFranchiseLoad.emit(this.franchise);
                });
            },
            error: error => {
                this.messageService.add({
                    severity: 'error',
                    detail: translate('Партньорът не съществува!'),
                });
                this.router.navigateByUrl('/partners');
            }
        });
        this.subscriptions.push(subscription);
    }

    // onTaxNumberSearch(event?: Event): void {
    //     const vatNumber = this.form?.get('company_tax_number')?.value;

    //     this.loadingQueue++;
    //     this.franchiseService.queryCompanyDetails(vatNumber).subscribe({
    //         next: response => {
    //             this.loadingQueue--;

    //             const [number, registered] = this.getTaxNumber(response?.data?.number);

    //             this.form?.get('company_tax_number')?.setValue((number || '').trim());
    //             this.form?.get('company_tax_registration')?.setValue((registered || false));
    //             this.form?.get('company_full_name')?.setValue((response?.data?.company || '').trim());
    //             this.form?.get('company_address')?.setValue((response?.data?.address || '').trim());
    //             this.form?.get('company_ceo')?.setValue((response?.data?.ceo || '').trim());
    //         },
    //         error: error => {
    //             this.loadingQueue--;

    //             this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
    //                 this.message = data.first || translate("Възникна грешка");
    //                 this.errors = data.errors;
    //             });
    //         }
    //     });
    // }

    protected getTaxNumber(source?: string|number): [number: string, registered: boolean] {
        return [
            ('' + (source || ''))?.replace(/[^\d+]/g, ''),
            ('' + (source || ''))?.replace(/[\d+]/g, '')?.length > 0
        ];
    }

    protected getTownList(): void {
        const subscription = this.townService.getTownList().subscribe((data: any) => {
                this.townList = data?.data;

                this.townList.unshift({
                    id: null,
                    name: translate('< Моля, изберете >'),
                });
            });

        this.subscriptions.push(subscription);
    }

    protected getFranchisePlans() {
        this.loadingQueue++;
        const subscription = this.franchiseService.getPlansList({limit: 1000}).subscribe({
            next: response => {
                this.loadingQueue--;
                this.planList = response?.data ?? [];

                if (!this.form?.get('franchise_plan')?.value) {
                    this.form?.get('franchise_plan')?.setValue(this.planList[0].id ?? null);
                }
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }

    protected *preferencesList(): Generator<PreferenceLoopItem> {
        for (let preference in this.preferences) {
            const attributes = this.preferences[preference];
            const type = attributes?.type ?? 'string';
            const fieldName = preference?.replace(/\./g, '_'); // convert all dots to _
            const fieldValue = type === 'boolean' && attributes?.customBoolean
                ? this.franchise?.getPreference(preference)?.value === attributes?.customBoolean?.enable
                : type === 'boolean'
                    ? ('' + this.franchise?.getPreference(preference)?.value) === 'true'
                    : this.franchise?.getPreference(preference)?.value;
            const defaultValue = type === 'boolean' ? true : '';

            yield {
                preference,
                attributes,
                type,
                fieldName,
                fieldValue,
                defaultValue,
            };
        }
    }

    protected mergePreferences(existing: FranchisePreference[], updates: FranchisePreference[]): FranchisePreference[] {
        const updatedNames = updates.map(pref => pref.name);
        const merged = existing
            .slice()
            .filter(pref => !updatedNames.includes(pref.name))
            .concat(updates)
            .map(item => (item.value = '' + (item.value ?? ''), item));

        return merged;
    }

    onTemplateFinished({files}: {files: UploadComponentQueue[]}, pageNumber: number): void {
        if (!files || !files.length) {
            this.messageFiles = 'Invalid format, upload issue or file is too big';
            this.messageFilesType = 'error';
        } else {
            files.map(item => {
                if (item.error) {
                    this.messageType = 'error';

                    const errorMessage = typeof item?.error !== 'string'
                        ? (item?.error?.error?.message ?? translate('Възникна неизвестна грешка!'))
                        : item?.error;

                    this.message = item?.file?.name + ": " + errorMessage;

                    this.messageFiles = translate("Невалиден формат, проблем с качването или файлът е прекалено голям: ") + item.file.name;

                    this.messageFilesType = 'error';
                    return;
                }

                this.message = '';
                this.messageFiles = '';

                if (pageNumber === 1) {
                    this.firstPage = item.response.data;
                    this.firstPage.title = item.file.name;
                    this.firstPage.size_kb = item.file.name;
                } else {
                    this.secondPage = item.response.data;
                    this.secondPage.title = item.file.name;
                    this.secondPage.size_kb = item.file.name;
                }
            });
        }
    }
}
