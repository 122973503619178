import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
    selector: 'pill',
    templateUrl: './pill.component.html',
    styleUrls: ['./pill.component.scss']
})
export class PillComponent {
    @Input() left: string|number = '';
    @Input() right: string|number = '';
    @Input() clickable: boolean = false;
    @Input() noWrapText: boolean = true;
    @Input() flip: boolean = true;

    @Output() click: EventEmitter<{event: MouseEvent}> = new EventEmitter();

    onClick(event?: any): void {
        this.click.emit(event);
    }
}
