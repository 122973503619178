import { Injectable } from '@angular/core';
import { map, Observable } from 'rxjs';
import { ApiService } from './api.service';
import { FilterSort } from '../models/filter-sort.model';
import { Payment } from '../models/payment.model';
import { Paginate } from '../models/paginate.model';

export type PaymentServiceIndexSortType = (
      'id'
    | 'price'
    | 'price_eur'
    | 'type'
    | 'date'
    | 'status'
    | 'updated'
);


export type PaymentServiceIncludeType = (
    'group.course'
    | 'user'
    | 'type'
    | 'group.franchise'
);

export interface PaymentServiceListFilter  {
    limit?: number,
    page?: number,
    q?: string;
    sort?: PaymentServiceIndexSortType|FilterSort<PaymentServiceIndexSortType>[],
    status?: string|('paid'|'unpaid'),
    include?: string|PaymentServiceIncludeType[],
    franchise?: number|null;
    course?: number|null;
    group?: number|null;
    user?: number|null;
};

export interface PaymentServiceInput {
    user?: number;
    group?: number;
    price?: number;
    date?: string;
    description?: string;
    type?: ('cash'|'transfer'|'paypal'|'bank');
    status?: ('paid'|'unpaid');
};

export interface PaymentServiceInputMyPos {
    cancel_url?: string;
    ok_url?: string;
};

@Injectable({
    providedIn: 'root'
})
export class PaymentService {

    constructor(
        protected api: ApiService,
    ) { }

    paypalPurchase(paymentId: number, order: string): Observable<{data: any}> {
        return this.api.post('/payments/' + paymentId + '/paypal', {order});
    }

    myposCreate(paymentId: number, data?: PaymentServiceInputMyPos): Observable<any> {
        return this.api.post('/payments/' + paymentId + '/mypos', data, {
            responseType: "text",
        });
    }

    /**
     *
     * @param filter
     * @param maxCacheTime
     * @returns
     */
    getPayments(filter?: PaymentServiceListFilter, maxCacheTime?: number): Observable<Paginate<Payment>> {
        filter && Object.keys(filter).forEach(key => key in filter && !(filter as any)[key] && delete (filter as any)[key]);
        filter && filter?.sort?.length && (typeof filter.sort === 'object') && (filter['sort'] = this.api.getSortParams(filter.sort) as PaymentServiceIndexSortType);
        filter && filter?.include?.length && typeof filter?.include === 'object' && (filter['include'] = filter?.include?.join(','));

        return this.api.get('/payments', {params: filter}, maxCacheTime).pipe(
            map(data => {
                data = Object.assign(new Paginate<Payment>(), data);
                data.data = data.data?.map((item: any)=> Payment.fromJson(item));
                return data;
            })
        );
    }

    getItem(id: number): Observable<{data: Payment}> {
        return this.api.get('/payments/' + id).pipe(
            map(response => {
                response.data = Payment.fromJson(response?.data);
                return response;
            })
        );
    }

    /**
     * add new record or records
     * @param paymentData
     * @returns
     */
    add(paymentData: PaymentServiceInput[]): Observable<{ data: Payment[] }> {
        return this.api.post('/payments', paymentData).pipe(
            map(response => {
                response.data = response.data?.map((item: any) => Payment.fromJson(item));
                return response;
            })
        );
    }

    /**
     * Update an existing record
     * @returns
     */
    edit(id: number, data: PaymentServiceInput): Observable<{data: Payment}> {
        return this.api.post('/payments/' + id, data).pipe(
            map(data => {
                data.data = Payment.fromJson(data?.data);
                return data;
            })
        );
    }

    delete(id: number): Observable<{data: any}> {
        return this.api.delete('/payments/' + id);
    }
}
