import { Location } from '@angular/common';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { UntypedFormControl, UntypedFormGroup, Validators } from '@angular/forms';
import { MessageService } from 'primeng/api';
import { Subscription } from 'rxjs';
import { UploadComponentQueue } from 'src/app/groups/module/module.component';
import { ApiValidation } from 'src/app/shared/services/api-validation.service';
import { User } from 'src/app/shared/models/user/user.model';
import { InputUser, UserService } from 'src/app/shared/services/user.service';
import { AuthService } from 'src/app/shared/services/auth.service';
import { TownService } from 'src/app/shared/services/town.service';
import { UserRoleName } from 'src/app/shared/models/user/user-role.model';
import { FranchiseService } from 'src/app/shared/services/franchise.service';
import { Franchise } from 'src/app/shared/models/franchise.model';
import { translate } from '@ngneat/transloco';
import { Group } from '../../models/group.model';
import * as moment from 'moment';

@Component({
    selector: 'modal-create-user',
    templateUrl: './modal-create-user.component.html',
    styleUrls: ['./modal-create-user.component.scss']
})
export class ModalCreateUserComponent {
    protected innerLoadingQueue: number = 0;
    protected subscriptions: Subscription[] = [];
    protected innerId: number|null = null;
    protected innerSubmit: boolean = false;

    student: User = new User;
    role: UserRoleName = 'student';
    user: User|null = null;
    form: UntypedFormGroup|null = null;
    photo: any = null;
    photoThumbnail: any = null;
    photoShare: any = null;
    errors: any = {};
    message: string = '';
    messageType: ('error'|'success') = 'error';
    blogIssue: string|null = null;
    statusList: Array<{id: string, name: string}> = [{
        id: 'active',
        name: translate('Видим'),
    }, {
        id: 'inactive',
        name: translate('Скрит'),
    }];
    townList: Array<{id: string|null, name: string}> = [];
    franchisesList: Franchise[] = [];

    @Output() onAdded: EventEmitter<User> = new EventEmitter();
    @Output() onAction: EventEmitter<string> = new EventEmitter();

    @Input() group: Group|null = null;
    @Input() skippable: boolean = true;

    @Output() loadingChange: EventEmitter<boolean> = new EventEmitter();
    @Input() loading: boolean = false;

    @Output() submitChange: EventEmitter<boolean> = new EventEmitter();
    @Input()
    set submit(value: boolean) {
        if (this.innerSubmit !== value) {
            this.innerSubmit = false;
            value && this.onSubmit();

            setTimeout(() => {
                this.submitChange.emit(this.innerSubmit);
            });
        }
    }
    get submit(): boolean {
        return this.innerSubmit;
    }

    @Input()
    set id(value: number|null) {
        if (this.innerId !== value) {
            this.innerId = value;
            this.innerId && this.fetchDetails(this.innerId);
        }
    }
    get id(): number|null {
        return this.innerId;
    }

    set loadingQueue(value: number) {
        if (this.innerLoadingQueue !== value) {
            this.innerLoadingQueue = value;

            if (this.innerLoadingQueue <= 0) {
                this.innerLoadingQueue = 0;
                this.loading = false;
            } else {
                this.loading = true;
            }

            this.loadingChange.emit(this.loading);
        }
    }
    get loadingQueue(): number {
        return this.innerLoadingQueue;
    }

    constructor(
        private userService: UserService,
        private authService: AuthService,
        private locationService: Location,
        private messageService: MessageService,
        private apiValidation: ApiValidation,
        private townService: TownService,
        private franchiseService: FranchiseService,
    ) { }

    ngOnInit(): void {
        this.user = this.authService.getUser();

        this.initForm();
        this.getTownList();

        if (this.user?.can(['admin'])) {
            this.getFranchises();
        }
    }

    ngOnDestroy(): void {
        this.subscriptions.map(item => item?.unsubscribe());
    }

    onFileFinished({files}: {files: UploadComponentQueue[]}): void {
        if (!files || !files.length || files[0].error) {
            this.message = 'Invalid image, upload issue or file is too big';
            this.messageType = 'error';
        } else {
            this.photo = {
                path: files[0].response?.data.path,
                url: files[0].response?.data.url,
            };
        }
    }

    onSubmit(event?: any): void {
        if (this.loading) {
            return;
        }

        this.form?.get('name')?.setValue((this.form?.get('name')?.value || '').trim());
        this.form?.get('surname')?.setValue((this.form?.get('surname')?.value || '').trim());
        this.form?.get('lastname')?.setValue((this.form?.get('lastname')?.value || '').trim());
        this.form?.get('email')?.setValue((this.form?.get('email')?.value || '').trim());
        this.form?.get('phone')?.setValue((this.form?.get('phone')?.value || '').trim());
        this.form?.get('password')?.setValue((this.form?.get('password')?.value || '').trim());

        this.form?.get('status')?.setValue((this.form?.get('status')?.value || '').trim());

        this.form?.get('parent_name')?.setValue((this.form?.get('parent_name')?.value || '').trim());
        this.form?.get('parent_email')?.setValue((this.form?.get('parent_email')?.value || '').trim());
        this.form?.get('parent_phone')?.setValue((this.form?.get('parent_phone')?.value || '').trim());

        this.form?.updateValueAndValidity();

        if (this.form?.invalid) {
            this.errors = this.form?.controls;
            this.messageService.add({
                severity: 'error',
                summary: translate('Грешка!'),
                detail: translate('Моля, проверете полетата отново!'),
            });
            return;
        }

        const birthday = moment(this.form?.get('birthday')?.value ?? '');
        const formData: InputUser = {
            role: this.role,

            ...(this.user?.can(['admin']) ? {
                franchise: this.form?.get('franchise')?.value ?? 0,
            } : null),

            email: this.form?.get('email')?.value ?? '',
            name: this.form?.get('name')?.value ?? '',
            surname: this.form?.get('surname')?.value ?? '',
            lastname: this.form?.get('lastname')?.value ?? '',
            image: this.photo.path || '',
            phone: this.form?.get('phone')?.value ?? '',
            password: this.form?.get('password')?.value || null,

            town: this.form?.get('town')?.value ?? '',
            birthday: birthday?.isValid() ? birthday.format("YYYY-MM-DD") : '',

            parent_name: this.form?.get('parent_name')?.value ?? '',
            parent_email: this.form?.get('parent_email')?.value ?? '',
            parent_phone: this.form?.get('parent_phone')?.value ?? '',

            status: this.form?.get('status')?.value ?? 'active',
        };

        this.loadingQueue++;
        const subscription = this.userService.add(formData).subscribe({
            next: response => {
                this.loadingQueue--;

                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно добавен курсист!'),
                });

                this.onAdded.emit(response?.data);
                this.onCloseAction(null);
            },
            error: (error: any) => {
                this.loadingQueue--;
                this.apiValidation.checkBadRequest(error, this.form).then((data: any) => {
                    this.messageService.add({
                        severity: 'error',
                        summary: translate('Грешка!'),
                        detail: data.message
                    });
                    this.errors = data.errors;
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    protected initForm(): void {
        const birthday = moment(this.student?.info?.birthday ?? '');


        this.form = new UntypedFormGroup({
            ...(this.user?.can(['admin']) ? {
                franchise: new UntypedFormControl(this.group?.franchise?.id ?? this.student?.franchise?.id, [
                    Validators.required,
                ]),
            } : null),

            email: new UntypedFormControl(this.student?.email, [
                Validators.required,
                Validators.maxLength(255),
                Validators.email,
            ]),
            phone: new UntypedFormControl(this.student?.phone, [
                Validators.required,
                Validators.maxLength(20),
            ]),
            name: new UntypedFormControl(this.student?.name, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            surname: new UntypedFormControl(this.student?.surname, [
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            lastname: new UntypedFormControl(this.student?.lastname, [
                Validators.required,
                Validators.minLength(2),
                Validators.maxLength(255),
            ]),
            password: new UntypedFormControl('', [
                Validators.required,
                Validators.minLength(8)
            ]),

            parent_name: new UntypedFormControl(this.student?.info?.parent_name, [
                Validators.minLength(2),
                Validators.maxLength(150),
            ]),
            parent_phone: new UntypedFormControl(this.student?.info?.parent_phone, [
                Validators.minLength(2),
                Validators.maxLength(20),
            ]),
            parent_email: new UntypedFormControl(this.student?.info?.parent_email, [
                Validators.minLength(2),
                Validators.maxLength(150),
                Validators.email,
            ]),

            birthday: new UntypedFormControl(birthday?.isValid() ? birthday.toDate() : '', [
                Validators.maxLength(255),
            ]),
            town: new UntypedFormControl(this.student?.info?.town?.id ?? null, [
                Validators.maxLength(255)
            ]),

            status: new UntypedFormControl(this.student?.status ?? 'active', [
                Validators.pattern('^(active|inactive)$')
            ]),
        });

        this.photo = {
            url: (this.student?.image || ''),
            path: (this.student?.image || ''),
        };
    }

    protected updateForm(): void {
        const birthday = moment(this.student?.info?.birthday ?? '');


        this.photo = {
            url: (this.student?.image || ''),
            path: (this.student?.image || ''),
        };

        if (this.form) {
            this.form.get('email')?.setValue((this.student?.email || '').trim());
            this.form.get('phone')?.setValue((this.student?.phone || '').trim());
            this.form.get('name')?.setValue((this.student?.name || '').trim());
            this.form.get('surname')?.setValue((this.student?.surname || '').trim());
            this.form.get('lastname')?.setValue((this.student?.lastname || '').trim());

            this.form.get('parent_name')?.setValue((this.student?.info?.parent_name || '').trim());
            this.form.get('parent_phone')?.setValue((this.student?.info?.parent_phone || '').trim());
            this.form.get('parent_email')?.setValue((this.student?.info?.parent_email || '').trim());

            this.form.get('town')?.setValue(this.student?.info?.town?.id ?? null);
            this.form.get('status')?.setValue(this.student?.status ?? 'active');
            this.form.get('birthday')?.setValue(birthday?.isValid() ? birthday.toDate() : '');

            if (this.user?.can(['admin'])) {
                this.form.get('franchise')?.setValue(this.student?.franchise?.id ?? 0);
            }
        }
    }

    protected fetchDetails(id: number): void {
        this.loadingQueue++;
        const subscription = this.userService.getItem(id).subscribe({
            next: data => {
                this.loadingQueue--;
                this.student = data.data ?? this.student;
                this.updateForm();
            },
            error: error => {
                this.loadingQueue--;
                this.messageService.add({
                    severity: 'error',
                    summary: translate('Грешка!'),
                    detail: translate('Курсистът не е намерен!'),
                });
                this.locationService.back();
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getFranchises() {
        this.loadingQueue++;
        const subscription = this.franchiseService.getList({limit: 1000}).subscribe({
            next: response => {
                this.loadingQueue--;
                this.franchisesList = response?.data ?? [];
            },
            error: error => {
                this.loadingQueue--;
            }
        });
        this.subscriptions.push(subscription);
    }

    protected getTownList(): void {
        this.loadingQueue++;
        const subscription = this.townService.getTownList().subscribe({
            next: response => {
                this.loadingQueue--;

                this.townList = response?.data as any[];

                this.townList.unshift({
                    id: null,
                    name: translate('< Моля, изберете >'),
                });
            },
            error: error => {
                this.loadingQueue--;
            }

        });

        this.subscriptions.push(subscription);
    }

    onCloseAction(e?: any): void {
        if (this.skippable) {
            this.onAction.emit('close');
        }
    }
}
