import { Injectable } from '@angular/core';
import { User } from '../models/user/user.model';
import { UserRoleName } from '../models/user/user-role.model';
import { AuthService } from './auth.service';
import { I18nService } from './i18n.service';

import { filter } from 'rxjs';
import { translate } from '@ngneat/transloco';
import { FranchiseService } from './franchise.service';
import { Franchise } from '../models/franchise.model';


export interface NavigationServiceMenu {
    name: string;
    url?: string;
    options?: any;
    title?: string;
    originalTitle: string;
    icon?: string;
    roles?: UserRoleName[];
    shouldHide?: (user?: User) => boolean;
};

@Injectable({
    providedIn: 'root'
})
export class NavigationService {

    protected navigation: NavigationServiceMenu[] = [{
        name: 'dashboard',
        url: "/dashboard",
        title: translate("Начало"),
        originalTitle: "Начало",
        icon: 'icon-house',
    }, {
        name: 'messages',
        url: "/messages",
        title: translate("Съобщения"),
        originalTitle: "Съобщения",
        icon: 'fas fa-envelope fa-fw',
        roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
    }, {
        name: 'profile',
        url: "/profile",
        title: translate("Профил"),
        originalTitle: "Профил",
        icon: 'icon-user',
        roles: ['admin', 'marketing', 'partner', 'teacher', 'student'],
    },{
        name: "courses",
        url: "/courses",
        title: translate("Курсове"),
        originalTitle: "Курсове",
        icon: 'fa-solid fa-book fa-fw',
        roles: ['admin', 'partner'],
    }, {
        name: "partners",
        url: "/partners",
        title: translate("Партньори"),
        originalTitle: "Партньори",
        icon: 'fa-regular fa-handshake fa-fw',
        roles: ['admin'],
    }, {
        name: "groups",
        url: "/groups",
        title: translate("Групи"),
        originalTitle: "Групи",
        icon: 'fa-solid fa-chalkboard-user fa-fw',
        roles: ['admin', 'partner', 'teacher', 'student'],
    }, {
        name: "teachers",
        url: "/teachers",
        title: translate("Преподаватели"),
        originalTitle: "Преподаватели",
        icon: 'fa-solid fa-person-chalkboard fa-fw',
        roles: ['admin', 'partner']
    }, {
        name: "students",
        url: "/students",
        title: translate("Курсисти"),
        originalTitle: "Курсисти",
        icon: 'fa-solid fa-user-graduate fa-fw',
        roles: ['admin', 'teacher', 'partner']
    }, {
        name: "homework",
        url: "/homework",
        title: translate("Домашни и проекти"),
        originalTitle: "Домашни и проекти",
        icon: 'icon-file',
        roles: ['student'],
    }, {
        name: "projects",
        url: "/homework",
        title: translate("Домашни и проекти"),
        originalTitle: "Домашни и проекти",
        icon: 'icon-file',
        roles: ['admin', 'partner', 'teacher'],
    }, {
        name: "payments",
        url: "/payments",
        title: translate("Плащания"),
        originalTitle: "Плащания",
        icon: 'icon-card',
        roles: ['student', 'admin', 'partner'],
    }, {
        name: "exams",
        url: "/exams",
        title: translate("Тестове"),
        originalTitle: "Тестове",
        icon: 'fa-solid fa-list-check fa-fw',
        roles: ['admin', 'partner', 'teacher', 'student'],
    }, {
        name: "certificates",
        url: "/certificates",
        title: translate("Сертификати"),
        originalTitle: "Сертификати",
        icon: 'icon-graduation-cap',
        roles: ['student', 'admin', 'partner'],
    }, {
        name: "calendar",
        url: "/calendar",
        title: translate("Календар"),
        originalTitle: "Календар",
        icon: 'icon-calendar',
        roles: ['admin', 'partner', 'teacher', 'student'],
    }, {
        name: "zoom",
        url: "/zoom",
        title: translate("Zoom"),
        originalTitle: "Zoom",
        icon: 'fa-solid fa-video fa-fw',
        roles: ['admin', 'partner']
    }, {
        name: "rooms",
        url: "/rooms",
        title: translate("Учебни зали"),
        originalTitle: "Учебни зали",
        icon: 'fa-solid fa-house-laptop fa-fw',
        roles: ['admin', 'partner']
    }, {
        name: "points",
        url: "/points",
        title: translate("Точки"),
        originalTitle: "Точки",
        icon: 'fa-solid fa-gift fa-fw',
        roles: ['admin','partner', 'teacher'],
    }, {
        name: "awards",
        url: "/awards",
        title: translate("Награди"),
        originalTitle: "Награди",
        icon: 'fa-solid fa-trophy fa-fw',
        shouldHide: (user?: User) => !user?.kid,
    }, {
        name: "awards",
        url: "/awards",
        title: translate("Заявени награди"),
        originalTitle: "Заявени награди",
        icon: 'fa-solid fa-trophy fa-fw',
        roles: ['admin','partner'],
    }, {
        name: "careers",
        url: "/careers",
        title: translate("Кариерен център"),
        originalTitle: "Кариерен център",
        icon: 'icon-job',
        roles: ['student'],
    }, {
        name: "jobs",
        url: "/jobs",
        title: translate("Обяви за работа"),
        originalTitle: "Обяви за работа",
        icon: 'icon-job',
        roles: ['admin', 'marketing', 'partner'],
    }, {
        name: "polls",
        url: "/polls",
        title: translate("Анкети"),
        originalTitle: "Анкети",
        icon: 'fa-solid fa-chart-bar fa-fw',
        roles: ['admin', 'marketing', 'partner'],
    }, {
        name: "blog",
        url: "/blog",
        title: translate("Блог"),
        originalTitle: "Блог",
        icon: 'fa-solid fa-newspaper fa-fw',
        roles: ['admin', 'marketing']
    }, {
        name: "attendances",
        url: "/attendances",
        title: translate("Събития и записвания"),
        originalTitle: "Събития и записвания",
        icon: 'fa-solid fa-martini-glass fa-fw',
        roles: ['admin', 'marketing'],
    }, {
        name: "instructions",
        url: "/about/instructions",
        title: translate("Инструкции"),
        originalTitle: "Инструкции",
        icon: 'icon-faq',
        roles: ['student'],
    }];

    company: Franchise|null = null;
    isCompetition: boolean = false;

    constructor(
        private authService: AuthService,
        private i18nService: I18nService,
        private franchiseService: FranchiseService,
    ) {
        this.franchiseService.getCompanyDetails().subscribe(data => this.company = data);
        this.isCompetition = this.company?.isPreference('competition',true) ?? false;

        this.i18nService.onLocaleChanged()
            .pipe(filter(locale => locale.language?.length > 0))
            .subscribe(locale => {
                this.navigation = this.navigation.map(menuItem => {
                    menuItem.title = translate(menuItem.originalTitle);
                    return menuItem;
                });
            });
    }

    ngOnInit() {}

    getNavigation(): NavigationServiceMenu[] {
        const user = this.authService.getUser() ?? User.fromJson({});

        return this.navigation.map(menuItem => {
                if (menuItem.name === "groups" && this.isCompetition) {
                    menuItem.title = translate("Състезания");
                }

                return menuItem;

            }).filter(menuItem => {
            if (menuItem?.roles?.length && !user?.can(menuItem?.roles)) {
                return false;
            }

            if (this.company?.isPreference('mainmenu.hide.' + menuItem.name, true)) {
                return false;
            }

            if (!(menuItem?.title?.length && menuItem?.url?.length && menuItem?.icon?.length)) {
                return false;
            }

            if (menuItem?.shouldHide && typeof menuItem?.shouldHide === 'function') {
                return !menuItem?.shouldHide(user);
            }

            return true;
        });
    }
}
