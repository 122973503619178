import { Injectable, Injector } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, tap } from 'rxjs';
import { AuthService } from '../services/auth.service';
import { Router } from '@angular/router';

@Injectable()
export class UnauthInterceptor implements HttpInterceptor {
    private authService: any = null;

    constructor(
        private injector: Injector,
        private router: Router
    ) {}

    intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
        return next.handle(request).pipe(tap({
            next: (event: HttpEvent<any>) => { },
            error: (error: any) => {
                if (error instanceof HttpErrorResponse && error.status === 401) {
                    // redirect to the login route
                    // or show a modal

                    if (!this.authService) {
                        this.authService = this.injector.get(AuthService);
                    }
                    this.authService.logout(true).subscribe((data: any) => {
                        setTimeout(() => {
                            this.router.navigate(['/auth/login']);
                        });
                    });
                }
            }
        }));
    }
}
