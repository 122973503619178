export const environment = {
    name: 'global',

    production: false,
    application: 'AdvanceAcademy.bg',
    domain: 'localhost',
    scheme: 'http',
    port: 8000,
    app_port: 4000,

    pagination: {
        default: 25,
        payments: 5,
        courses: 2,
        jobs: 12,
        home: 6,
        other_groups: 5,
    },

    debounce: {
        // default Search field debounce timeout in ms
        default: 750,
    },

    routes: {
        home: '/',
        guest: '/auth/login',
    },

    services: {
        recaptcha: {
            key: '6LeXo2cUAAAAANViYrvfPLxzK5VfAOmtTNU-5FlM',
        },
        paypal: {
            key: 'AW_fxPJSdjEMAL3SAwWy6Q7wjnU5uzNoqARO-9xpkmvz25PrppVoBHDQrSsABkhMS6X25IPP-Z_OafcQ',
        },
        firebase: {
            projectId: 'advance-academy',
            appId: '1:206383106389:web:85eaea024ccfd2949987dc',
            databaseURL: 'https://advance-academy.firebaseio.com',
            storageBucket: 'advance-academy.appspot.com',
            locationId: 'europe-west3',
            apiKey: 'AIzaSyAFXb7RQx8QZxBkd6khO61J1-S5EAqarno',
            authDomain: 'advance-academy.firebaseapp.com',
            messagingSenderId: '206383106389',
            measurementId: 'G-V2VFSHBN7C',
        }
    },

    caching: {
        default: 5 * 60 * 1000, // 5 min
        sidebar: 15 * 60 * 1000, // 15 min. for most of sidebar items
    },

    features: {
        // Later will turn it on - it's annoying during development
        recaptcha: false,
    },

    locales: {
        // all available locales
        // @note: Do not translate labels!
        // @note2: DON'T use language-only codes!
        available: [{
            'id': 'bg_BG',
            'label': "Български език",
        }, {
            'id': 'en_US',
            'label': "English Language",
        // }, {
        //     'id': 'ru_RU',
        //     'label': "Русский язык",
        // }, {
        //     'id': 'uk_UA',
        //     'label': "Українська мова",
        }],

        // Default locale as fallback
        default: 'bg_BG'
    },
};
