<loading [loading]="loading" *ngIf="!(courses?.data?.length || (withPartners && partners?.data?.length))" [hasData]="mode !== 'classic'"></loading>

<!-- Sidebar mode -->
<ng-container *ngIf="mode === 'sidebar'">
    <div #sidebar class="offcanvas offcanvas-end" tabindex="-1" id="offcanvasRight" aria-labelledby="offcanvasRightLabel">
        <div class="offcanvas-header py-2 px-3 p-md-3">
            <h3 class="offcanvas-title mb-0" id="offcanvasRightLabel"><i class="fa-solid fa-sliders fa-fw"></i> {{ 'Филтри' | transloco }}</h3>
            <button type="button" class="btn btn-default rounded-0 border-0 p-2 close-btn" data-bs-dismiss="offcanvas" aria-label="Close">
                <i class="fa-lg fa-solid fa-xmark"></i>
            </button>
        </div>
        <div class="offcanvas-body">
            <div class="row" *ngIf="!loading || courses?.data?.length || partners?.data?.length">
                <ng-container   [ngTemplateOutlet]="forms"
                                [ngTemplateOutletContext]="{

                                }"
                ></ng-container>
            </div>

        </div>
        <div class="offcanvas-footer d-flex align-items-center justify-content-start p-3" *ngIf="hasSelectedAnyFilter">
            <div class="row mt-auto">
                <div class="col-12">
                    <button class="btn btn-outline-danger btn-sm" (click)="onFilterClearClicked($event)" data-bs-dismiss="offcanvas" >
                        <i class="fa fa-times fa-fw"></i>
                        {{ 'Изчисти филтрите' | transloco }}
                    </button>
                </div>
            </div>
        </div>
    </div>
</ng-container>
<!-- Sidebar mode -->


<!-- Searchable mode -->
<ng-container *ngIf="mode === 'searchable' && (!loading || courses?.data?.length)">
    <div class="row">
        <ng-container   [ngTemplateOutlet]="forms"
                        [ngTemplateOutletContext]="{

                        }"
        ></ng-container>
    </div>
</ng-container>
<!-- Searchable mode -->


<!-- Classic mode / Old Drag-Scroll Design -->
<ng-container *ngIf="mode === 'classic'">

    <drag-scroll *ngIf="withPartners && (currentUser && (currentUser | roleAny:'admin'))"
                class="courses-wrapper d-flex flex-nowrap nice-scrollbars p-0"
                infinite-scroll
                [scrollWindow]="false"
                [horizontal]="true"
                infiniteScrollContainer=".drag-scroll-content"
                (scrolled)="onScrollPartners($event)"
    >
        <ng-container *ngFor="let partner of partners?.data">
            <div *ngIf="(partner?.id ?? 0) > 0"
                class="col course me-1 me-xl-3"
                [class.selected]="selectedPartner?.id === partner?.id"
                (click)="onPartnerToggle($event, partner)"
            >
                <div class="name">{{ partner?.name }}</div>
                <loading [loading]="loading"></loading>
                <img [src]="partner?.icon ?? partner?.logo" [alt]="partner?.name" [title]="partner?.name" (error)="partner.imageError = true" [class.has-error]="partner.imageError ?? false" />
            </div>

            <div *ngIf="!(partner?.id ?? 0)"
                class="col course me-3 me-xl-4 highlight"
                [class.selected]="selectedPartner?.id === partner?.id"
                (click)="onPartnerToggle($event, partner)"
            >
                <div class="name"></div>
                <div class="cover text-center d-flex flex-column align-items-center justify-content-center">
                    <i class="fa-solid fa-user-gear fa-fw fa-3x mt-auto mb-xl-2"></i>
                    <h2 class="text-center text-uppercase mt-2 mb-auto">{{ partner?.name }}</h2>
                </div>
            </div>
        </ng-container>
    </drag-scroll>

    <drag-scroll class="courses-wrapper d-flex flex-nowrap nice-scrollbars p-0"
                infinite-scroll
                [scrollWindow]="false"
                [horizontal]="true"
                infiniteScrollContainer=".drag-scroll-content"
                (scrolled)="onScrollCourses($event)"
    >
        <ng-container *ngFor="let course of courses?.data">
            <div *ngIf="(course?.id ?? 0) > 0"
                class="col course me-1 me-xl-3"
                [class.selected]="selectedCourse?.id === course?.id"
                (click)="onCourseToggle($event, course)"
            >
                <div class="name">{{ course?.title }}</div>
                <loading [loading]="loading"></loading>
                <img [src]="course?.image" [alt]="course?.title" [title]="course?.title" />
            </div>

            <div *ngIf="!(course?.id ?? 0)"
                class="col course me-3 me-xl-4 highlight"
                [class.selected]="selectedCourse?.id === course?.id"
                (click)="onCourseToggle($event, course)"
            >
                <div class="name"></div>
                <div class="cover text-center d-flex flex-column align-items-center justify-content-center">
                    <i class="fa-solid fa-user-gear fa-fw fa-3x mt-auto mb-xl-2"></i>
                    <h2 class="text-center text-uppercase mt-2 mb-auto">{{ course?.title }}</h2>
                </div>
            </div>
        </ng-container>
    </drag-scroll>

    <drag-scroll class="groups-wrapper d-flex flex-nowrap nice-scrollbars mt-4"
                infinite-scroll
                [scrollWindow]="false"
                [horizontal]="true"
                infiniteScrollContainer=".drag-scroll-content"
                (scrolled)="onScrollGroups($event)"
                *ngIf="groups?.data?.length"
    >
        <div *ngFor="let group of groups?.data"
            class="col-12 group me-2"
            [class.expired]="group?.expired"
            [class.selected]="selectedGroup?.id === group?.id"
            (click)="onGroupToggle($event, group)"
        >
            <div class="row flex-nowrap flex-row align-items-center">
                <div class="col-auto position-relative">
                    <img [src]="group?.course?.logo" [alt]="group?.title" [title]="group?.title">
                    <loading [loading]="loading"></loading>
                </div>
                <div class="col">
                    <h3>{{ group?.title }}</h3>
                    <p>{{ group?.parsed_date_start | dateLocale:'shortDate'}} - {{ group?.parsed_date_to | dateLocale:'shortDate' }}</p>
                </div>
            </div>
        </div>
    </drag-scroll>

    <div class="users-wrapper">
        <drag-scroll class="users-container d-flex flex-nowrap nice-scrollbars mt-4 w-100"
                    infinite-scroll
                    [scrollWindow]="false"
                    [horizontal]="true"
                    (scrolled)="onScrollUsers($event)"
                    [ngClass]="{'no-users': users?.data?.length === 0}"
                    infiniteScrollContainer=".drag-scroll-content"
        >
            <div    *ngFor="let user of users?.data"
                    (click)="onUserToggle($event, user)"
                    class="d-inline-flex flex-column align-items-center justify-content-start cursor-pointer tabUser"
            >
                <avatar class="mt-3 mx-3 mb-1"
                        [user]="user || null"
                        size="big"
                        type="drop"
                        [selected]="selectedUser?.id === user?.id"
                        [highlight]="selectedUser?.id === user?.id"
                        [loading]="loading"
                ></avatar>

                <p class="mb-3 mt-2">{{user | userName }}</p>
            </div>
        </drag-scroll>
    </div>
</ng-container>


<!-- Form Field Items -->
<ng-template #forms>
    <!-- Above filter -->
    <ng-container *ngIf="mode === 'sidebar' && above && (above?.length ?? 0) > 0">
        <div
                *ngFor="let aboveFilter of above; let aboveItemIndex = index;"
                class="col-12 sidebar-offcanvas"
                [ngClass]="{
                    'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                    'my-2': mode === 'sidebar'
                }"
        >
            <label [id]="'filterCourseCustomAbove' + aboveItemIndex" [class.text-muted]="loading">{{ aboveFilter?.title }}:</label>

            <p-dropdown requiredField
                        [requiredFieldAttribute]="'filterCourseCustomAbove' + aboveItemIndex"
                        [filter]="aboveFilter?.filter ?? false"
                        [options]="aboveFilter?.options || []"
                        [ngModel]="aboveFilter?.value ?? null"
                        optionValue="value"
                        optionLabel="label"
                        [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                        [emptyMessage]="'Няма намерени резултати' | transloco"
                        [inputId]="'filterCourseCustomAbove' + aboveItemIndex"
                        class="dropdown my-2 w-100"
                        panelStyleClass="dropdown-panel-fixed-width"
                        styleClass="w-100"
                        [appendTo]="(mode === 'sidebar') ? null : 'body'"
                        (onChange)="onChangeCustomFilter($event, aboveFilter?.name)"
                        [disabled]="loading"
            >
                <ng-template let-selected pTemplate="selectedItem">
                     <!-- primeng 16+ workaround for null-values -->
                    <ng-container *ngIf="selected">{{ selected?.label }}</ng-container>
                    <ng-container *ngIf="!selected">{{ aboveFilter?.options?.[0]?.label }}</ng-container>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-container>
    <!-- Above filter -->

    <!-- Partners -->
    <div    *ngIf="currentUser && (currentUser | roleAny:'admin') && withPartners && (level === 'course' || level === 'course-group' || level === 'course-group-user')"
            class="sidebar-offcanvas"
            [ngClass]="{
                'col-12': level === 'course' || (mode === 'sidebar'),
                'col-lg-6': level === 'course-group' && !(mode === 'sidebar'),
                'col-lg-4': level === 'course-group-user' && !(mode === 'sidebar'),

                'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                'my-2': mode === 'sidebar'
            }"
    >
        <label id="filterCourseItem"  [class.text-muted]="loading || !partners?.data?.length">{{ 'Партньор:' | transloco }}</label>

        <p-dropdown requiredField
                    requiredFieldAttribute="filterCourseItem"
                    [filter]="true"
                    filterBy="name"
                    [options]="partners?.data || []"
                    [ngModel]="selectedPartner?.id ?? null"
                    optionValue="id"
                    optionLabel="name"
                    [placeholder]="'< ' + ('Моля, изберете партньор' | transloco) + ' >'"
                    [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                    [emptyMessage]="'Няма намерени резултати' | transloco"
                    inputId="filterCourseItem"
                    class="dropdown my-2 w-100"
                    panelStyleClass="dropdown-panel-fixed-width"
                    styleClass="w-100"
                    [appendTo]="(mode === 'sidebar') ? null : 'body'"
                    (onChange)="onChangePartner($event)"
                    [disabled]="loading || !partners?.data?.length"
        >
            <ng-template let-partner pTemplate="item">
                <div class="d-flex align-items-center">
                    <avatar *ngIf="partner?.id > 0" [photo]="partner?.icon ?? partner?.logo ?? ''" class="me-2" size="tiny"></avatar>
                    <div class="d-flex flex-column text-start">
                        <div>{{ partner?.name }}</div>
                    </div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- Partners -->

    <!-- Course -->
    <div    *ngIf="level === 'course' || level === 'course-group' || level === 'course-group-user'"
            class="sidebar-offcanvas"
            [ngClass]="{
                'col-12': level === 'course' || (mode === 'sidebar'),
                'col-lg-6': level === 'course-group' && !(mode === 'sidebar'),
                'col-lg-4': level === 'course-group-user' && !(mode === 'sidebar'),

                'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                'my-2': mode === 'sidebar'
            }"
    >
        <label id="filterCourseItem"  [class.text-muted]="loading || !courses?.data?.length">{{ 'Курс:' | transloco }}</label>

        <p-dropdown requiredField
                    requiredFieldAttribute="filterCourseItem"
                    [options]="courses?.data || []"
                    [ngModel]="selectedCourse?.id ?? null"
                    optionValue="id"
                    optionLabel="title"
                    [placeholder]="'< ' + ('Моля, изберете курс' | transloco) + ' >'"
                    [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                    [emptyMessage]="'Няма намерени резултати' | transloco"
                    inputId="filterCourseItem"
                    class="dropdown my-2 w-100"
                    [filter]="true"
                    [filterFields]="['title','id']"
                    panelStyleClass="dropdown-panel-fixed-width"
                    styleClass="w-100"
                    [appendTo]="(mode === 'sidebar') ? null : 'body'"
                    (onChange)="onChangeCourse($event)"
                    [disabled]="loading || !courses?.data?.length"
        >
            <ng-template let-course pTemplate="item">
                <div class="d-flex align-items-center">
                    <avatar *ngIf="course?.id > 0" [photo]="course?.logo ?? ''" class="me-2" size="tiny"></avatar>
                    <div class="d-flex flex-column justify-content-between text-start w-100 overflow-hidden">
                        <div [title]="course?.title">
                            <div *ngIf="course?.id" class="ms-1 float-end position-relative" style="z-index:1">
                                <small class="d-inline-block badge text-bg-secondary p-1">#{{ course?.id }}</small>
                            </div>
                            {{ course?.title }}
                        </div>
                        <span class="small fst-italic" [class.text-muted]="selectedCourse?.id !== course?.id">{{ course?.created | dateLocale:'shortDate'}}</span>
                    </div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- Course -->

    <!-- Group -->
    <div    *ngIf="level === 'course-group' || level === 'course-group-user'"
            class="sidebar-offcanvas"
            [ngClass]="{
                'col-12': (mode === 'sidebar'),
                'col-lg-6': level === 'course-group' && !(mode === 'sidebar'),
                'col-lg-4': level === 'course-group-user' && !(mode === 'sidebar'),

                'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                'my-2': mode === 'sidebar'
            }"
    >
        <label id="filterGroupItem" [class.text-muted]="loading || !groups?.data?.length">{{ 'Група:' | transloco }}</label>

        <p-dropdown requiredField
                    requiredFieldAttribute="filterGroupItem"

                    [options]="groups?.data ?? []"
                    [ngModel]="selectedGroup?.id ?? null"
                    optionValue="id"
                    optionLabel="title"
                    [placeholder]="'< ' + ('Моля, изберете група' | transloco) + ' >'"
                    [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                    [emptyMessage]="'Няма намерени резултати' | transloco"
                    inputId="filterGroupItem"
                    class="dropdown my-2 w-100"
                    styleClass="w-100"
                    [filter]="true"
                    [filterFields]="['title','id']"
                    panelStyleClass="dropdown-panel-fixed-width"
                    [appendTo]="(mode === 'sidebar') ? null : 'body'"
                    (onChange)="onChangeGroup($event)"
                    [disabled]="loading || !groups?.data?.length"
        >
            <ng-template let-group pTemplate="item">
                <div class="col d-flex flex-column text-start overflow-hidden">
                    <div class="w-100" [title]="group?.title">
                        <div *ngIf="group?.id" class="ms-1 float-end position-relative" style="z-index:1">
                            <small class="d-inline-block badge text-bg-secondary p-1">#{{ group?.id }}</small>
                        </div>
                        {{ group?.title }}
                    </div>
                    <small *ngIf="group?.date_start && group?.date_to"
                        class="small fst-italic mb-0"
                        [class.text-muted]="selectedGroup?.id !== group?.id"
                    >{{ group?.parsed_date_start | dateLocale:'shortDate'}} - {{ group?.parsed_date_to | dateLocale:'shortDate' }}</small>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- Group -->

    <!-- Student -->
    <div    *ngIf="level === 'course-group-user'"
            class="sidebar-offcanvas"
            [ngClass]="{
                'col-12': (mode === 'sidebar'),
                'col-lg-4': !(mode === 'sidebar'),

                'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                'my-2': mode === 'sidebar'
            }"
    >
        <label id="filterUserItem" [class.text-muted]="loading || !users?.data?.length">{{ 'Курсист:' | transloco }}</label>

        <p-dropdown requiredField
                    requiredFieldAttribute="filterUserItem"
                    [filter]="true"
                    filterBy="fullName"
                    [options]="users?.data ?? []"
                    [ngModel]="selectedUser?.id ?? null"
                    optionValue="id"
                    optionLabel="fullName"
                    filterBy="searchBy"
                    [placeholder]="'< ' + ('Моля, изберете курсист' | transloco) + ' >'"
                    [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                    [emptyMessage]="'Няма намерени резултати' | transloco"
                    inputId="filterUserItem"
                    class="dropdown my-2 w-100"
                    styleClass="w-100"
                    panelStyleClass="dropdown-panel-fixed-width"
                    [appendTo]="(mode === 'sidebar') ? null : 'body'"
                    (onChange)="onChangeUser($event)"
                    [disabled]="loading || !users?.data?.length"
        >
            <ng-template let-student pTemplate="item">
                <div class="d-flex align-items-center">
                    <avatar *ngIf="student?.id > 0" [user]="student || null" class="me-2" size="tiny"></avatar>
                    <div class="d-flex flex-column text-start">
                        <div>{{ student | userName }}</div>
                        <div *ngIf="student?.email" class="small fst-italic mb-0" [class.text-muted]="selectedUser?.id !== selectedUser?.id">{{ student?.email }}</div>
                    </div>
                </div>
            </ng-template>
        </p-dropdown>
    </div>
    <!-- Student -->

    <!-- Below filter -->
    <ng-container *ngIf="mode === 'sidebar' && below && (below?.length ?? 0) > 0">
        <div
                *ngFor="let belowFilter of below; let belowItemFilter = index;"
                class="col-12 sidebar-offcanvas"
                [ngClass]="{
                    'mt-lg-4 mt-2 mb-lg-3 mb-1': mode !== 'sidebar',
                    'my-2': mode === 'sidebar'
                }"
        >
            <label [id]="'filterCourseCustomBelow' + belowItemFilter"  [class.text-muted]="loading">{{ belowFilter?.title }}:</label>

            <p-dropdown requiredField
                        [requiredFieldAttribute]="'filterCourseCustomBelow' + belowItemFilter"
                        [filter]="belowFilter?.filter ?? false"
                        [options]="belowFilter?.options || []"
                        [ngModel]="belowFilter?.value ?? null"
                        optionValue="value"
                        optionLabel="label"
                        [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                        [emptyMessage]="'Няма намерени резултати' | transloco"
                        [inputId]="'filterCourseCustomBelow' + belowItemFilter"
                        class="dropdown my-2 w-100"
                        panelStyleClass="dropdown-panel-fixed-width"
                        styleClass="w-100"
                        [appendTo]="(mode === 'sidebar') ? null : 'body'"
                        (onChange)="onChangeCustomFilter($event, belowFilter?.name)"
                        [disabled]="loading"
            >
                <ng-template let-selected pTemplate="selectedItem">
                    <!-- primeng 16+ workaround for null-values -->
                    <ng-container *ngIf="selected">{{ selected?.label }}</ng-container>
                    <ng-container *ngIf="!selected">{{ belowFilter?.options?.[0]?.label }}</ng-container>
                </ng-template>
            </p-dropdown>
        </div>
    </ng-container>
    <!-- Below filter -->

</ng-template>

