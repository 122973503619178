import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
    name: 'filter'
})
export class FilterPipe implements PipeTransform {

    /**
     *
     * @param value
     * @param field Field name. Dot-notation supported
     * @param fieldValue
     * @returns
     */
    transform(value: Array<any>, field: string = 'status', fieldValue: string|number|boolean = 'active'): Array<any> {
        const values: any[] = typeof fieldValue === 'number' || typeof fieldValue === 'boolean'
            ? [fieldValue]
            : (fieldValue + '').split(',');

        return (value || []).filter(item => {
            const fieldValue = this.getValueByDotNotation(item, field);
            return values.indexOf(fieldValue) >= 0;
        });
    }

    private getValueByDotNotation(obj: any, field: string): any {
        return field.split('.').reduce((acc, part) => acc ? acc[part] : undefined, obj);
    }
}
