import { Component, Input, OnDestroy, OnInit } from '@angular/core';
import { Module } from '../../models/module.model';
import { ActivatedRoute, Router } from '@angular/router';
import { User } from '../../models/user/user.model';
import { AuthService } from '../../services/auth.service';
import { CertificatesService } from '../../services/certificates.service';
import { MessageService } from 'primeng/api';
import { translate } from '@ngneat/transloco';
import { Certificate } from '../../models/certificate/certificate.model';
import { Subscription } from 'rxjs';
import { MenuActionsComponentAction } from '../menu-actions/menu-actions.component';

@Component({
    selector: 'certificate',
    templateUrl: './certificate.component.html',
    styleUrls: ['./certificate.component.scss'],
})
export class CertificateComponent implements OnInit, OnDestroy {
    protected innerCertificate: Certificate|null = null;
    protected subscriptions: Subscription[] = [];

    @Input() isLink: boolean = false;
    @Input() isExpandable: boolean = false;
    @Input() shareLink: string|null = null;
    @Input() profileView: boolean = false;
    @Input() editButton:boolean = false;

    @Input()
    set certificate(val: Certificate|null) {
        if (this.innerCertificate !== val) {
            this.innerCertificate = val;

            this.slides = this.chunk(
                this.chunk(this.innerCertificate?.group?.course?.modules, 8),
                2
            );

            // remove shared image
            if ((this.innerCertificate?.thumbnails?.length ?? 0) >= 2) {
                this.innerCertificate?.thumbnails?.pop();
            }

            if (this.innerCertificate?.thumbnails?.length) {
                this.innerCertificate.thumbnails = this.innerCertificate?.thumbnails?.map(item => {
                    return item + '?v=' + (new Date).getTime();
                });
            }
        }
    }
    get certificate(): Certificate|null {
        return this.innerCertificate;
    }

    modules: Module[] = [];
    slides: any = [];
    currentSlideIndex = 0;
    isExpanded: boolean = false;
    facebookLink: string = '';
    linkedInLink: string = '';
    user: User = new User();
    loading: boolean = false;


    menuActions: MenuActionsComponentAction[] = [
        { text: translate('Актуализиране'), icon: 'fa-solid fa-pen-to-square', id: 'update' },
        { text: translate('Курсист'), icon: 'fa-solid fa-user-graduate', id: 'user' },
        { divider: true },
        { text: translate('Група'), icon: 'fa-solid fa-chalkboard-user', id: 'group' },
        { divider: true },
        { text: translate('Изтриване'), icon: 'fa-solid fa-trash-can text-danger', id: 'remove' },
    ]

    showDelete: boolean = false;
    showUpdate: boolean = false;

    constructor(
        private route: ActivatedRoute,
        private authService: AuthService,
        private certificateService: CertificatesService,
        private messageService: MessageService,
        private router: Router,
    ) { }

    ngOnInit(): void {
        this.facebookLink = `https://www.facebook.com/sharer.php?u=${encodeURIComponent(
            this.shareLink ?? window.location.href
        )}`;
        this.linkedInLink = `https://www.linkedin.com/sharing/share-offsite/?url=${encodeURIComponent(
            this.shareLink ?? window.location.href
        )}`;

        this.getCertificateMode();

        this.user = this.authService.getUser() ?? this.user;
    }

    ngOnDestroy(): void {
        this.subscriptions?.map(subscription => subscription?.unsubscribe());
    }

    protected getCertificateMode() {
        this.isExpanded = this.route.snapshot.params['uuid'];
    }

    chunk(modules: any, size: number) {
        const chunks = [];
        modules = [].concat(modules);

        while (modules.length) {
            chunks.push(modules.splice(0, size));
        }

        return chunks;
    }

    nextSlide() {
        if(this.currentSlideIndex < this.slides.length) {
             this.currentSlideIndex++
        }
    }
    backSlide() {
        if(this.currentSlideIndex < this.slides.length) {
            this.currentSlideIndex--
       }
    }

    onButtonClicked(buttonId: string): void {
        this.showUpdate = (buttonId === 'update');
        this.showDelete = (buttonId === 'remove');

        if (buttonId === 'group') {
            this.router.navigateByUrl('/groups/' + this.certificate?.group?.id);
            return;
        }
        if (buttonId === 'user') {
            this.router.navigateByUrl('/students/' + this.certificate?.user?.id);
            return;
        }
    }

    onUpdateConfirmAction(action?: any): void {
        this.showUpdate = false;

        if (action !== 'yes' || this.loading === true) {
            return;
        }

        this.loading = true;
        const subscription = this.certificateService.update(this.certificate?.id ?? 0).subscribe({
            next: response => {
                this.loading = false;
                this.certificate = response?.data;

                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно редактиран сертификат!'),
                });
            },
            error: (error) => {
                this.loading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: translate('Грешка!'),
                    detail: translate('Грешка при актуализиране на сертификат!'),
                });
            }
        });
        this.subscriptions.push(subscription);
    }

    onDeleteConfirmAction(action?: any): void {
        this.showDelete = false;

        if (action !== 'yes' || this.loading === true) {
            return;
        }

        this.loading = true;
        const subscription = this.certificateService.delete(this.certificate?.id ?? 0).subscribe({
            next: (response) => {
                this.loading = false;

                this.messageService.add({
                    severity: 'success',
                    detail: translate('Успешно изтрит сертификат!'),
                });
                this.router.navigate(['/certificates']);
            },
            error: (error) => {
                this.loading = false;

                this.messageService.add({
                    severity: 'error',
                    summary: translate('Грешка!'),
                    detail: translate('Грешка при изтриването на сертификат!'),
                });
            }
        });
        this.subscriptions.push(subscription);
    }
}
