<form *ngIf="form" (submit)="onSubmit($event)" [formGroup]="form" class="profile-form standard-form mb-5" autocomplete="off">
    <div class="card mb-3" *ngIf="!loading || franchise?.id">
        <div class="card-header">
            <h3 class="my-0 fw-bold">{{ 'Връщане към палитра' | transloco }}</h3>
        </div>
        <div class="card-body">
            <div class="row">
                <div class="col">
                    <p-dropdown
                                [filter]="true"
                                filterBy="name"
                                [options]="colorThemesList"
                                [(ngModel)]="colorTheme"
                                [placeholder]="'< ' + ('Моля, изберете тема' | transloco) + ' >'"
                                [emptyFilterMessage]="'Няма намерени резултати' | transloco"
                                [emptyMessage]="'Няма намерени резултати' | transloco"
                                class="d-block my-2 w-100"
                                styleClass="w-100"
                                optionValue="value"
                                optionLabel="name"
                                [ngModelOptions]="{standalone: true}"
                                (onChange)="onReset()"
                    ></p-dropdown>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3" *ngIf="!loading || franchise?.id">
        <div class="card-header">
            <h3 class="my-0 fw-bold">{{ 'Основни фонови цветове' | transloco }}</h3>
        </div>
        <div class="card-body" >
            <div class="col-12">
                <div class="row">
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorPrimary', label: ('Първи цвят:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorPrimaryDark', label: ('Първи цвят (тъмен нюанс):' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorPrimaryLight', label: ('Първи цвят (светъл нюанс):' | transloco) }"
                    ></ng-container>
                    <div class="col-12"><hr /></div>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSecondary', label: ('Втори цвят:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSecondaryDark', label: ('Втори цвят (тъмен нюанс):' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSecondaryLight', label: ('Втори цвят (светъл нюанс):' | transloco) }"
                    ></ng-container>
                    <div class="col-12"><hr /></div>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorThird', label: ('Трети цвят:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorThirdDark', label: ('Трети цвят (тъмен нюанс):' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorThirdLight', label: ('Трети цвят (светъл нюанс):' | transloco) }"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3" *ngIf="!loading || franchise?.id">
        <div class="card-header">
            <h3 class="my-0 fw-bold">{{ 'Цветове за текст и линкове' | transloco }}</h3>
        </div>
        <div class="card-body">
            <div class="col-12">
                <div class="row">
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorText', label: ('Цвят на текста:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorTextDark', label: ('Цвят на текста (тъмен нюанс):' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorTextLight', label: ('Цвят на текста (светъл нюанс):' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorTextInvert', label: ('Цвят на текста (инвертиран):' | transloco) }"
                    ></ng-container>

                    <div class="col-12"><hr /></div>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorLink', label: ('Цвят на линк:' | transloco)}"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorLinkHover', label: ('Цвят на линк (върху елемента):' | transloco)}"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorLinkActive', label: ('Цвят на линк (натискане):' | transloco)}"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3" *ngIf="!loading || franchise?.id">
        <div class="card-header">
            <h3 class="my-0 fw-bold">{{ 'Цветове за бутони' | transloco }}</h3>
        </div>
        <div class="card-body">
            <div class="col-12">
                <div class="row">
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnPrimary', label: ('Първичен (фон):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnPrimaryHover', label: ('Първичен (върху елемента):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnPrimaryActive', label: ('Първичен (натискане):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnPrimaryText', label: ('Първичен (цвят на текста):' | transloco) }"
                    ></ng-container>
                    <div class="col-12"><hr /></div>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSecondary', label: ('Вторичен (фон):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSecondaryHover', label: ('Вторичен (върху елемента):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSecondaryActive', label: ('Вторичен (натискане):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSecondaryText', label: ('Вторичен (цвят на текста):' | transloco) }"
                    ></ng-container>
                    <div class="col-12"><hr /></div>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSignup', label: ('Записване (фон):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSignupHover', label: ('Записване (върху елемента):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSignupActive', label: ('Записване (натискане):' | transloco) }"
                    ></ng-container>

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorBtnSignupText', label: ('Записване (цвят на текста):' | transloco) }"
                    ></ng-container>


                </div>
            </div>
        </div>
    </div>

    <div class="card mb-3" *ngIf="!loading || franchise?.id">
        <div class="card-header">
            <h3 class="my-0 fw-bold">{{ 'Главно меню' | transloco }}</h3>
        </div>
        <div class="card-body">
            <div class="col-12">
                <div class="row">

                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSidebar', label: ('Основен фон:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSidebarActive', label: ('Фон при активен режим:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSidebarText', label: ('Основен цвят:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSidebarTextHover', label: ('Цвят върху елемента:' | transloco) }"
                    ></ng-container>
                    <ng-container
                            [ngTemplateOutlet]="field"
                            [ngTemplateOutletContext]="{ name: 'themeColorSidebarTextActive', label: ('Цвят при натискане:' | transloco) }"
                    ></ng-container>
                </div>
            </div>
        </div>
    </div>

    <div class="submit-panel bg-white py-3 mb-5 text-center">
        <button class="btn btn-primary"
                    [disabled]="loading ? true : false"
                    type="submit"
            >
                <i *ngIf="loading" class="fa-solid fa-spinner fa-fw fa-pulse"></i>
                <i *ngIf="!loading" class="fa-solid fa-check fa-fw"></i>

                {{ 'Запиши' | transloco }}
        </button>
    </div>

    <ng-template #field let-label="label" let-name="name" let-cols="cols">
        <div [class]="cols ?? 'col-sm-4 col-md-3 mb-3'">
            <div class="form-group">
                <label [for]="name">{{ label }}</label>
                <ngx-colors     requiredField [requiredFieldAttribute]="name"
                                ngx-colors-trigger
                                [formControlName]="name"
                                colorsAnimation="popup"
                                [acceptLabel]="'Избор' | transloco"
                                [cancelLabel]="'Отказ' | transloco"
                                [class.is-invalid]="errors?.[name]?.status === 'INVALID'"
                                (input)="onChangeColor($event)"
                ></ngx-colors>
            </div>
        </div>
    </ng-template>
</form>


<modal-confirm  *ngIf="showRefreshAskModal"
                (onAction)="onModalRefresh($event)"
                [closable]="false"
                body=""
>
    <p class="mt-4">{{ 'Желаете ли презареждане на страницата, за да видите резултатите от промените?' | transloco }}</p>
</modal-confirm>
