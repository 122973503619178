<header class="border-bottom py-0 py-lg-2">
    <nav class="navbar navbar-expand-md" aria-label="Fourth navbar example">
        <div class="container-fluid">
            <div class="navbar-brand d-none d-md-inline-flex">
                <ng-container *ngIf="!company?.id || company?.primary; else customCompanyHeadline">
                    <a routerLink="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-decoration-none" [title]="'Начало' | transloco">
                        <img src="/assets/img/logos/logo.svg" alt="">
                    </a>
                    <a [href]="'tel:' + (company?.phone?.length ? company?.phoneFormatted : '+359889990030')" class="contact-number d-flex align-items-center" [title]="'Телефон за връзка' | transloco">
                        <div class="row g-0">
                            <div class="col-auto align-items-center me-2 d-none d-lg-inline-flex">
                                <i class="fa-solid fa-phone fa-fw fa-lg"></i>
                            </div>
                            <div class="col" style="max-width: 180px">
                                <div class="row">
                                    <div class="col-12">{{ 'Телефон за контакти:' | transloco }}</div>
                                    <div class="col-12 fw-bold text-nowrap phone text-truncate">{{ company?.phone ?? '+359 889 990 030' }} </div>
                                </div>
                            </div>
                        </div>
                    </a>
                </ng-container>

                <ng-template #customCompanyHeadline>
                    <a routerLink="/" class="d-flex align-items-center my-2 my-lg-0 me-lg-auto text-decoration-none custom-logo" [title]="'Начало' | transloco">
                        <img [src]="company?.logo" alt="" *ngIf="company?.id">
                    </a>
                    <a [href]="'tel:' + company?.phoneFormatted" class="contact-number d-flex align-items-center" [title]="'Телефон за връзка' | transloco" *ngIf="company?.phone?.length">
                        <div class="row g-0">
                            <div class="col-auto align-items-center me-2 d-none d-lg-inline-flex">
                                <i class="fa-solid fa-phone fa-fw fa-lg"></i>
                            </div>
                            <div class="col" style="max-width: 180px">
                                <div class="row">
                                    <div class="col-12">{{ 'Телефон за контакти:' | transloco }}</div>
                                    <div class="col-12 fw-bold text-nowrap phone text-truncate">{{ company?.phone }}</div>
                                </div>
                            </div>
                        </div>
                    </a>
                </ng-template>
            </div>

            <button class="navbar-toggler" type="button" data-bs-toggle="offcanvas" data-bs-target="#offcanvasExample" aria-controls="offcanvasExample">
                <i class="fa-solid fa-bars"></i>
            </button>

            <app-language-switcher mode="dropdown" class="me-auto d-md-none lang-switcher mobile"></app-language-switcher>

            <div class=" justify-content-end d-block d-md-none d-lg-none d-xl-none">
                <ul class="navbar-nav flex-row align-items-center">
                    <li class="nav-item mx-2 px-2" *ngIf="!navCalendarHide">
                        <a routerLink="/calendar">
                            <div class="icon-bell"></div>
                        </a>
                    </li>
                    <li class="nav-item mx-2 px-1" *ngIf="!navMessagesHide">
                        <a routerLink="/messages">
                            <div class="icon-email position-relative" [class.bounce]="currentNotificationsCount > 0">
                                <span *ngIf="currentNotificationsCount > 0" class="notification-indicator" [class.smaller]="currentNotificationsCount > 99">
                                    {{ currentNotificationsCount < 100 ? currentNotificationsCount : ('99+') }}
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item ms-4 me-2">
                        <a href="#" class="d-flex d-flex align-items-center justify-content-end text-decoration-none" id="profileDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <avatar [user]="user" size="tiny"></avatar>
                        </a>

                        <ul class="dropdown-menu dropdown-menu-end text-small shadow position-absolute" aria-labelledby="profileDropdownMenu">
                            <li><a class="dropdown-item" routerLink="/profile">{{ 'Профил' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        <ng-container *ngIf="user && (user | roleAny:'partner')">
                            <li><a class="dropdown-item" routerLink="/company">{{ 'Фирмени данни' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        </ng-container>
                            <li><a class="dropdown-item" routerLink="/auth/logout">{{ 'Изход' | transloco }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>

            <div class="collapse navbar-collapse justify-content-end" >
                <ul class="navbar-nav">
                    <!-- <a href="tel:+359889990030" class="nav-item mx-3 text-white" [title]="'Телефон за връзка' | transloco">
                        <div class="row g-0">
                            <div class="col-auto align-items-center flex-row me-2 d-none d-lg-inline-flex">
                                <i class="fa-solid fa-phone fa-fw fa-2x"></i>
                            </div>
                            <div class="col-12" style="max-width: 180px">
                                <div class="row">
                                    <div class="col-12">{{ 'Телефон за контакти:' | transloco }}</div>
                                    <div class="col-12  fw-bold text-nowrap" style="font-size: 18px;">+359 889 990 030</div>
                                </div>
                            </div>
                        </div>
                    </a> -->
                    <li class="nav-item mx-3">
                        <app-language-switcher mode="dropdown" class="lang-switcher me-4"></app-language-switcher>
                    </li>
                    <li class="nav-item mx-2 d-flex align-items-center" *ngIf="!navCalendarHide">
                        <a routerLink="/calendar">
                            <div class="icon-bell"></div>
                        </a>
                    </li>
                    <li class="nav-item mx-2 d-flex align-items-center" *ngIf="!navMessagesHide">
                        <a routerLink="/messages">
                            <div class="icon-email position-relative" [class.bounce]="currentNotificationsCount > 0">
                                <span *ngIf="currentNotificationsCount > 0" class="notification-indicator" [class.smaller]="currentNotificationsCount > 99">
                                    {{ currentNotificationsCount < 100 ? currentNotificationsCount : ('99+') }}
                                </span>
                            </div>
                        </a>
                    </li>
                    <li class="nav-item">
                        <a href="#" class="d-flex d-flex h-100 align-items-center justify-content-end text-decoration-none dropdown-toggle" id="profileDropdownMenu" data-bs-toggle="dropdown" aria-expanded="false">
                            <div class="d-flex align-items-start ms-2">
                                <div class="mx-2 text-end text user-name">
                                    {{ user | userName }}
                                    <small class="d-block user-role-title">{{ user?.role?.title }}</small>
                                </div>
                                <avatar [user]="user"></avatar>
                            </div>
                        </a>
                        <ul class="dropdown-menu dropdown-menu-end text-small shadow" aria-labelledby="profileDropdownMenu">
                            <li><a class="dropdown-item" routerLink="/profile">{{ 'Профил' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        <ng-container *ngIf="user && (user | roleAny:'partner')">
                            <li><a class="dropdown-item" routerLink="/company">{{ 'Фирмени данни' | transloco }}</a></li>
                            <li><hr class="dropdown-divider"></li>
                        </ng-container>
                            <li><a class="dropdown-item" routerLink="/auth/logout">{{ 'Изход' | transloco }}</a></li>
                        </ul>
                    </li>
                </ul>
            </div>
        </div>
      </nav>

    <div class="offcanvas offcanvas-start" tabindex="-1" id="offcanvasExample" aria-labelledby="offCanvasSidebar">
        <div class="offcanvas-header pb-1">
            <h5 class="offcanvas-title mb-0" id="offCanvasSidebar">
                <ng-container *ngIf="!company?.id || company?.primary; else customCompanyMobileMenu">
                    <img src="/assets/img/logos/logo-reverse.svg" alt="">
                </ng-container>

                <ng-template #customCompanyMobileMenu>
                    <img [src]="company?.logo" alt="" class="custom-company">
                </ng-template>
            </h5>
            <button type="button" class="btn-close text-reset" data-bs-dismiss="offcanvas" aria-label="Close"></button>
        </div>
        <div class="offcanvas-body">
            <ul class="nav nav-pills nav-flush flex-column mb-auto border-top">
                <li class="nav-item mt-1" *ngFor="let menu of navigation">
                    <a  href="#"
                        class="nav-link py-3 px-2"
                        aria-current="page"
                        [title]="menu.title"
                        [routerLink]="menu.url"
                        routerLinkActive="active"
                        [routerLinkActiveOptions]="menu.options || {exact: false}"
                        data-bs-dismiss="offcanvas" aria-label="Close"
                    >
                        <i [class]="menu.icon"></i>
                        {{menu.title}}
                    </a>
                </li>

                <li class="nav-item mt-1">
                    <div class=" border-top pt-2"></div>
                </li>

                <ng-container *ngIf="!company?.id || company?.primary; else customCompanyDetails">
                    <li class="nav-item mt-1">
                        <a  href="tel:+359889990030"
                            class="nav-link py-3 px-2 side-social-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            target="_blank"
                        >
                            <i class="icon-phone"></i>
                            {{ 'Телефон' | transloco }}
                        </a>
                    </li>
                    <li class="nav-item mt-1">
                        <a href="https://www.facebook.com/advanceacademy.bg"
                            class="nav-link py-3 px-2 side-social-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            target="_blank"
                        >
                            <i class="fa-fw fa-brands fa-facebook-f"></i>
                            {{ 'Facebook' | transloco }}
                        </a>
                    </li>
                </ng-container>

                <ng-template #customCompanyDetails>
                    <li class="nav-item mt-1" *ngIf="company?.phone?.length ?? company?.phoneFormatted">
                        <a  [href]="'tel:' + company?.phoneFormatted"
                            class="nav-link py-3 px-2 side-social-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            target="_blank"
                        >
                            <i class="icon-phone"></i>
                            {{ 'Телефон' | transloco }}
                        </a>
                    </li>

                    <li class="nav-item mt-1" *ngIf="company?.facebook?.length">
                        <a  [href]="company?.facebook"
                            class="nav-link py-3 px-2 side-social-icon"
                            data-bs-toggle="tooltip"
                            data-bs-placement="right"
                            target="_blank"
                        >
                            <i class="fa-fw fa-brands fa-facebook-f"></i>
                            {{ 'Facebook' | transloco }}
                        </a>
                    </li>
                </ng-template>
            </ul>
        </div>
    </div>
</header>
